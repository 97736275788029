import { t } from "@lingui/macro"
import { Box, Button, Stack, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"

import { PollModalStore } from "src/modals/polls/store"
import { PollOptionsSection } from "src/modals/polls/components/PollOptions"
import { Add24 } from "src/components/icons/Add24"
import { Icon } from "src/components/icons"
import { useStore } from "src/store/lib/useStore"
import { ModalTextField } from "src/components/ModalTextField"
import {
    POLL_FIELD_TYPE_DROPDOWN_OPTIONS,
    POLL_CONSTANTS,
} from "src/modals/polls/constants/constants"
import { FieldTypeDropdown } from "src/components/Inputs/FieldTypeDropdown"
import { PollTypeKeys } from "src/modals/polls/types"

export const QuestionsConfigSection = observer(() => {
    const store = useStore(PollModalStore)
    const theme = useTheme()

    const options = store.form.get("options")
    const showRemoveButton = useMemo(
        () => options.length > POLL_CONSTANTS.MIN_OPTIONS && !store.isReadOnly,
        [options.length, store.isReadOnly],
    )

    const handleQuestionChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (store.isReadOnly) return
            const value = event.target.value
            store.form.set("question", value)
            store.form.set("name", value)
        },
        [store.isReadOnly, store.form],
    )

    const handleOptionChange = useCallback(
        (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
            if (store.isReadOnly) return
            const updatedOptions = [...options]
            updatedOptions[index] = event.target.value
            store.form.set("options", updatedOptions)
        },
        [store.isReadOnly, options, store.form],
    )

    const handleRemoveOption = useCallback(
        (index: number) => {
            if (store.isReadOnly) return
            const updatedOptions = [...options]
            updatedOptions.splice(index, 1)
            store.form.set("options", updatedOptions)
        },
        [store.isReadOnly, options, store.form],
    )

    const handleAddOption = useCallback(() => {
        if (store.isReadOnly) return
        store.form.set("options", [...options, ""])
    }, [store.isReadOnly, options, store.form])

    const handleTypeChange = useCallback(
        (value: string) => {
            store.form.set("questionType", value as PollTypeKeys)
            store.form.set("options", [...options])
        },
        [options, store.form],
    )

    const iconProps = useMemo(
        () => ({
            icon: <Add24 />,
            size: POLL_CONSTANTS.DEFAULT_ICON_SIZE,
            color: theme.palette.info.main,
        }),
        [theme.palette.info.main],
    )

    const renderPollOption = useCallback(
        (option: string, index: number) => {
            const errorMessage =
                index === 0
                    ? store.form.error("options") ?? undefined
                    : undefined

            return (
                <PollOptionsSection
                    key={index}
                    type={store.form.get("questionType")}
                    value={option}
                    errorMessage={errorMessage}
                    onChange={(e) => handleOptionChange(index, e)}
                    onRemove={() => handleRemoveOption(index)}
                    showRemoveButton={showRemoveButton}
                    index={index}
                    disabled={store.isReadOnly}
                />
            )
        },
        [
            store.isReadOnly,
            showRemoveButton,
            store.form,
            handleOptionChange,
            handleRemoveOption,
        ],
    )

    const pollOptions = useMemo(
        () => options.map(renderPollOption),
        [options, renderPollOption],
    )

    const addOptionButton = useMemo(
        () =>
            !store.isReadOnly && (
                <Button
                    startIcon={<Icon {...iconProps} />}
                    color="info"
                    onClick={handleAddOption}
                    sx={{ alignSelf: "flex-start" }}
                >
                    {t`poll.question-config.add-option-button`}
                </Button>
            ),
        [store.isReadOnly, iconProps, handleAddOption],
    )

    return (
        <Stack sx={{ gap: 2, width: "100%" }}>
            <FieldTypeDropdown
                item={{
                    value: store.form.get("questionType"),
                    errorMessage: store.form.error("questionType") ?? "",
                }}
                onSelectValueChange={handleTypeChange}
                disabled={store.isReadOnly}
                dropdownOptions={POLL_FIELD_TYPE_DROPDOWN_OPTIONS}
            />
            <Box sx={{ flex: 1 }}>
                <ModalTextField
                    variant="default"
                    label={t`poll.question-config.question-label`}
                    value={store.form.get("question")}
                    onChange={handleQuestionChange}
                    error={Boolean(store.form.error("question"))}
                    helperText={store.form.error("question")}
                    disabled={store.isReadOnly}
                    maxLength={POLL_CONSTANTS.MAX_QUESTION_LENGTH}
                />
            </Box>
            <Stack spacing={2}>
                {pollOptions}
                {addOptionButton}
            </Stack>
        </Stack>
    )
})
