import { Tooltip } from "@mui/material"
import { t } from "@lingui/macro"
import { useMemo } from "react"

import { RowCellContainer } from "src/components/RowCellContainer"
import { ISegmentIdsCellProps } from "src/components/TableCells/SegmentIdsCell/types"

export const SegmentIdsCell = ({ segmentNames }: ISegmentIdsCellProps) => {
    const count = useMemo(() => segmentNames.length ?? 0, [segmentNames])

    const tooltipText = useMemo(
        () => segmentNames?.join(", ") ?? "",
        [segmentNames],
    )

    return useMemo(() => {
        if (count === 0) {
            return <RowCellContainer>{t`global.no-segments`}</RowCellContainer>
        }
        if (count === 1) {
            return <RowCellContainer>{segmentNames[0]}</RowCellContainer>
        }

        return (
            <Tooltip title={tooltipText}>
                <RowCellContainer>{`(${count}) ${t`global.segments`}`}</RowCellContainer>
            </Tooltip>
        )
    }, [count, segmentNames, tooltipText])
}
