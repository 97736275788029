import { observer } from "mobx-react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { t } from "@lingui/macro"
import { useCallback, useMemo } from "react"

import {
    FieldTypeDropdownIconContainer,
    FieldTypeDropdownItemContainer,
} from "src/components/Inputs/FieldTypeDropdown/styled"
import { DROPDOWN_VALUES } from "src/components/Inputs/FieldTypeDropdown/constants"
import {
    IFieldTypeDropdownItem,
    IFieldTypeDropdownProps,
} from "src/components/Inputs/types/IFieldTypeDropdown"

export const FieldTypeDropdown = observer(
    ({
        item,
        onSelectValueChange,
        disabled,
        dropdownOptions,
    }: IFieldTypeDropdownProps) => {
        const dropdownOptionsArray = useMemo(
            () =>
                dropdownOptions != null && dropdownOptions.length > 0
                    ? DROPDOWN_VALUES.filter((option) =>
                          dropdownOptions.includes(option.value),
                      )
                    : DROPDOWN_VALUES,
            [dropdownOptions],
        )

        const renderDropdownOption = useCallback(
            (option: IFieldTypeDropdownItem) => {
                return (
                    <MenuItem value={option.value} key={option.value}>
                        <FieldTypeDropdownItemContainer>
                            <FieldTypeDropdownIconContainer>
                                {option.icon}
                            </FieldTypeDropdownIconContainer>
                            {option.name}
                        </FieldTypeDropdownItemContainer>
                    </MenuItem>
                )
            },
            [],
        )

        return (
            <FormControl style={{ breakInside: "avoid", width: "100%" }}>
                <InputLabel id="type-label">
                    {t`field-type-dropdown.dropdown-label`}
                </InputLabel>
                <Select
                    data-testid="connector-dropdown"
                    labelId="connector-label"
                    id="connector"
                    value={item.value}
                    disabled={disabled}
                    error={Boolean(item.errorMessage)}
                    label={t`field-type-dropdown.dropdown-label`}
                    onChange={(event) =>
                        onSelectValueChange(event.target.value)
                    }
                >
                    {dropdownOptionsArray.map(renderDropdownOption)}
                </Select>
            </FormControl>
        )
    },
)
