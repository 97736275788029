import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { TemplateFeatureAdminService } from "src/api"
import { Channel } from "src/channel"
import { shouldPublishSegments } from "src/helpers/shouldPublishSegments"
import { FormFields } from "src/lib/form-fields"
import { reportSuccess, reportError } from "src/lib/report"
import { ITemplateFeatureFormFields } from "src/modals/template-features/types/template-feature-form-fields"
import { EModalMode } from "src/types/modal/modal"

export class TemplateFeatureModalStore {
    //#region initialization
    static Context = React.createContext<TemplateFeatureModalStore | null>(null)

    formFields = new FormFields<ITemplateFeatureFormFields>({
        name: "",
        description: "",
        segment_ids: [],
        access_type: "NONE",
    })

    private _isLoading = false
    private _id: number | undefined = undefined
    //#endregion

    //#region getters
    get isLoading() {
        return this._isLoading
    }

    private get id() {
        return this._id ?? undefined
    }

    get isEditMode() {
        return this.id !== undefined
    }

    get isReadOnly() {
        return this.formFields.get("access_type") === "READ"
    }
    //#endregion

    //#region setters
    setIsLoading = (loading: boolean) => {
        this._isLoading = loading
    }

    setId = (id: number) => {
        this._id = id
    }
    //#endregion

    constructor() {
        makeAutoObservable(this)
    }

    //#region store operations
    init = async (id?: number, accessGroupId?: number, mode?: EModalMode) => {
        //create
        if (id === undefined) {
            this.formFields.init({
                access_group_id: accessGroupId ?? 0,
            })
            return
        }

        //update
        try {
            const response =
                await TemplateFeatureAdminService.getV1AdminTemplatefeature({
                    id,
                })

            this.formFields.init({
                access_group_id: response.access_group_id,
                name: response.name ?? "",
                description: response.description ?? "",
                segment_ids: response.segment_ids ?? [],
            })

            if (mode === EModalMode.Copy) {
                this.formFields.set("segment_ids", [])
                this.formFields.set("access_group_id", accessGroupId)
            } else {
                this.setId(id)
            }
        } catch (error) {
            reportError(t`template-features.get-fail`, error)
        }
    }

    handleSubmit = async () => {
        if (!this.validateForm()) return

        this.id === undefined ? await this.create() : await this.update(this.id)
    }

    private validateForm = () => {
        this.formFields.clearErrors()
        this.formFields.validateRequiredFields([
            { field: "name" },
            { field: "description" },
            { field: "access_group_id" },
        ])

        return !this.formFields.hasErrors()
    }

    private create = async () => {
        try {
            const segmentIds = this.formFields.get("segment_ids")
            this.setIsLoading(true)

            const response =
                await TemplateFeatureAdminService.postV1AdminTemplatefeature({
                    request: {
                        name: this.formFields.get("name") ?? "",
                        description: this.formFields.get("description") ?? "",
                        access_group_id:
                            this.formFields.get("access_group_id") ?? 0,
                        // Add other fields as needed
                    },
                })

            if (Boolean(response.id) && shouldPublishSegments(segmentIds)) {
                await this.publish(response.id)
            }

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "template-feature",
                    action: "create",
                },
            })
            reportSuccess(t`template-features.create-success`)
        } catch (error) {
            reportError(t`template-features.create-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    private update = async (id: number) => {
        try {
            this.setIsLoading(true)

            await TemplateFeatureAdminService.putV1AdminTemplatefeature({
                id,
                request: {
                    name: this.formFields.get("name") ?? "",
                    description: this.formFields.get("description") ?? "",
                },
            })

            await this.publish(this.id)

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "template-feature",
                    action: "update",
                },
            })

            reportSuccess(t`template-features.update-success`)
        } catch (error) {
            reportError(t`template-features.update-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    private publish = async (id?: number) => {
        const segmentIds = this.formFields.get("segment_ids")

        if (id !== undefined && shouldPublishSegments(segmentIds)) {
            try {
                await TemplateFeatureAdminService.putV1AdminTemplatefeaturePublish(
                    {
                        id: id,
                        request: {
                            segment_ids: segmentIds,
                        },
                    },
                )
            } catch (error) {
                reportError(t`global.publish-fail`, error)
            }
        }
    }
    //#endregion
}
