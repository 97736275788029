export enum FieldTypeDropdownValues {
    RadioButton = "Radio Button",
    Paragraph = "Paragraph",
    Checkbox = "Checkboxes",
    ShortAnswer = "Short answer",
    Dropdown = "Dropdown",
    FileUpload = "File Upload",
}

export enum FieldTypeDropdownKeys {
    RadioButton = "radio",
    Paragraph = "textarea",
    Checkbox = "checkbox",
    ShortAnswer = "text",
    Dropdown = "select",
    FileUpload = "file",
}

export interface IFieldTypeDropdownProps {
    item: { value: string; errorMessage: string }
    onSelectValueChange: (value: string) => void
    disabled?: boolean
    dropdownOptions?: Array<FieldTypeDropdownKeys>
}
export interface IFieldTypeDropdownItem {
    name: FieldTypeDropdownValues
    icon: React.ReactNode
    value: FieldTypeDropdownKeys
}
