import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { PollAdminService, poll_PollListItem } from "src/api"
import { CustomDataHelper } from "src/api/_custom/services/DataHelper"
import { Channel } from "src/channel"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { parseDate } from "src/lib/date"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { reportError } from "src/lib/report"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"
import { IPollsItem } from "src/views/polls/types/polls-item"

export class PollStore {
    //#region initialization
    static Context = React.createContext<PollStore | null>(null)
    static LoadingKeys = createLoadingKeys("loading")

    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    _segments: number[] = []

    private repositoryUpdatesListenerDisposer?: () => void

    polls = new Pagination(
        async (params) => {
            const response = await PollAdminService.postV1AdminPollList({
                request: {
                    filter: params.advanceQuery,
                    page_number: params.page,
                    page_size: params.pageSize,
                    ...CustomDataHelper.PrepareVisibilityData(this),
                },
            })

            const items: IPollsItem[] = (response.polls ?? []).map(
                (poll: poll_PollListItem) => ({
                    poll_id: poll.id ?? -1,
                    id: poll.id ?? -1,
                    question: poll.question ?? "",
                    name: poll.question ?? "",
                    access_group_id: poll.access_group_id ?? -1,
                    access_type: poll.access_type ?? "NONE",
                    author_id: -1,
                    author_type: "admin_user",
                    created_at: parseDate(poll.created_at),
                    updated_at: parseDate(poll.updated_at),
                    close_at: parseDate(poll.close_at),
                    segment_ids: poll.segment_ids ?? [],
                    segment_names: poll.segment_names ?? [],
                    status: poll.status ?? "open",
                    views_count: poll.vote_count ?? 0,
                    votes_count: poll.vote_count ?? 0,
                    vote_options: [],
                    vote_type: "single",
                }),
            )

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        {
            loadingKey: PollStore.LoadingKeys.loading,
        },
    )

    constructor() {
        makeAutoObservable(this)
    }
    //#endregion

    //#region getters
    get segments() {
        return this._segments
    }

    private get accessGroupId() {
        return this._accessGroupId
    }
    //#endregion

    //#region setters
    private setSegments(segments: number[]) {
        this._segments = segments
    }

    private setAccessGroup(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }
    //#endregion

    //#region store operations
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this.listenToRepositoryUpdates()
        this.setAccessGroup(accessGroupId)
        try {
            await this.polls.loadInitialPage(advanceQuery)
        } catch (error) {
            reportError("Error initializing poll list", error)
        }
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    async loadSegments(segments: number[], advanceQuery?: IAdvanceQueryModel) {
        this.setSegments(segments)
        await this.polls.loadInitialPage(advanceQuery)
    }

    deleteItem = async (id: number) => {
        try {
            await PollAdminService.deleteV1AdminPoll({
                pollId: id,
            })
        } catch (error) {
            reportError(t`polls.delete-item-fail`, error)
        } finally {
            await this.polls.reload()
        }
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.polls.loadAdvanceQuery(advanceQuery)
    }

    private listenToRepositoryUpdates() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "poll"
                ) {
                    await this.polls.reload()
                }
            },
        )
    }
    //#endregion
}
