import { t } from "@lingui/macro"
import { TextField } from "@mui/material"
import { observer } from "mobx-react"

import { FeatureConfigurationDetailStore } from "src/modals/feature-configuration-detail/store"
import { useStore } from "src/store/lib/useStore"

export const EmailTemplateSection = observer(() => {
    const store = useStore(FeatureConfigurationDetailStore)

    return (
        <>
            <TextField
                label={t`feature-configuration-detail-modal.email-template-section.welcome-newly-moved-in-label`}
                value={store.form.get("emailTemplateTenantMoveIn")}
                onChange={store.form.setter("emailTemplateTenantMoveIn")}
            />
            <TextField
                label={t`feature-configuration-detail-modal.email-template-section.register-reminder-label`}
                value={store.form.get("emailTemplateTenantReminderRegister")}
                onChange={store.form.setter(
                    "emailTemplateTenantReminderRegister",
                )}
            />
            <TextField
                label={t`feature-configuration-detail-modal.email-template-section.final-reminder-label`}
                value={store.form.get("emailTemplateTenantFinalReminder")}
                onChange={store.form.setter("emailTemplateTenantFinalReminder")}
            />
            <TextField
                label={t`feature-configuration-detail-modal.email-template-section.tenant-rollout-registration-reminder-label`}
                value={store.form.get(
                    "emailTemplateTenantRolloutRegistrationReminder",
                )}
                onChange={store.form.setter(
                    "emailTemplateTenantRolloutRegistrationReminder",
                )}
            />
            <TextField
                label={t`feature-configuration-detail-modal.email-template-section.tenant-rollout-registration-reminder-2-label`}
                value={store.form.get(
                    "emailTemplateTenantRolloutRegistrationReminder2",
                )}
                onChange={store.form.setter(
                    "emailTemplateTenantRolloutRegistrationReminder2",
                )}
            />
            <TextField
                label={t`feature-configuration-detail-modal.email-template-section.tenant-rollout-registration-reminder-final-label`}
                value={store.form.get(
                    "emailTemplateTenantRolloutRegistrationReminderFinal",
                )}
                onChange={store.form.setter(
                    "emailTemplateTenantRolloutRegistrationReminderFinal",
                )}
            />
            <TextField
                label={t`feature-configuration-detail-modal.email-template-section.register-reminder-post-movein-label`}
                value={store.form.get(
                    "emailTemplateTenantPostMoveInRemindEmail",
                )}
                onChange={store.form.setter(
                    "emailTemplateTenantPostMoveInRemindEmail",
                )}
            />
            <TextField
                label={t`feature-configuration-detail-modal.email-template-section.active-electricity-label`}
                value={store.form.get("emailTemplateTenantElectricityEmail")}
                onChange={store.form.setter(
                    "emailTemplateTenantElectricityEmail",
                )}
            />
            <TextField
                label={t`feature-configuration-detail-modal.email-template-section.active-home-insurance-label`}
                value={store.form.get("emailTemplateTenantInsuranceEmail")}
                onChange={store.form.setter(
                    "emailTemplateTenantInsuranceEmail",
                )}
            />
            <TextField
                label={t`feature-configuration-detail-modal.email-template-section.termination-request-label`}
                value={store.form.get("emailTemplateTenantTerminationRequest")}
                onChange={store.form.setter(
                    "emailTemplateTenantTerminationRequest",
                )}
            />
            <TextField
                label={t`feature-configuration-detail-modal.email-template-section.termination-confirmation-label`}
                value={store.form.get(
                    "emailTemplateTenantTerminationConfirmation",
                )}
                onChange={store.form.setter(
                    "emailTemplateTenantTerminationConfirmation",
                )}
            />
        </>
    )
})
