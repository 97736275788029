import { IFieldsVerificationDataSet } from "src/modals/contact-forms-form-builder/types/fieldsVerficationDataset"
import { FieldTypeDropdownKeys } from "src/components/Inputs/types/IFieldTypeDropdown"

export const fieldsVerificationDataSet: IFieldsVerificationDataSet = {
    dropdownValue: {
        verificationType: [],
    },
    inputList: {
        verificationType: [
            FieldTypeDropdownKeys.RadioButton,
            FieldTypeDropdownKeys.Checkbox,
            FieldTypeDropdownKeys.Dropdown,
        ],
    },
    label: {
        verificationType: [],
    },
}
