import { FieldTypeDropdownKeys } from "src/components/Inputs/types/IFieldTypeDropdown"

export const shouldHaveOptionsArray = (value: string) => {
    switch (value) {
        case FieldTypeDropdownKeys.RadioButton:
            return true
        case FieldTypeDropdownKeys.Checkbox:
            return true
        case FieldTypeDropdownKeys.Dropdown:
            return true
        default:
            return false
    }
}
