import { t } from "@lingui/macro"
import { Alert, Button, Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useState, useMemo } from "react"

import { PollModalStore } from "./store"
import { StyledTab, TabContainer } from "./styled"
import { QuestionsTab } from "./tabs/Questions"
import { ResponsesTab } from "./tabs/Responses"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { useInitializer } from "src/lib/initializer"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { reportError } from "src/lib/report"
import { IPollModalProps } from "src/modals/polls/types/poll-modal"
import { EModalMode } from "src/types/modal/modal"

const Modal = observer((props: IPollModalProps) => {
    const store = useStore(PollModalStore)
    const gstore = useStore(GlobalStore)
    const [activeTab, setActiveTab] = useState(props.tabId ?? 0)

    useCloseConfirmationForForm(store.form)

    const initialized = useInitializer(
        () => store.init(props.id, props.sourceContext, props.mode),
        [props.id, props.sourceContext, props.mode],
    )

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            // Check if the submit event is triggered by clicking on a tab
            const nativeEvent = event.nativeEvent as SubmitEvent
            const submitter = nativeEvent.submitter as HTMLElement
            if (
                Boolean(submitter) &&
                (submitter.classList.contains("StyledTab") ||
                    submitter.closest(".StyledTab") != null)
            ) {
                event.preventDefault()
                return
            }

            event.preventDefault()
            try {
                // Submit the form
                await store.submit()
                // Check if form has validation errors
                if (store.form.hasErrors()) {
                    return
                }
                // Successfully saved, close the modal
                gstore.modals.pop()
            } catch (error) {
                // Display error toast
                reportError(t`poll.error.submit`)
                // Still close the modal on error, since the error is displayed in the toast
                gstore.modals.pop()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const handleTabChange = useCallback((_: unknown, value: number) => {
        setActiveTab(value)
    }, [])

    const handleQuestionsTabClick = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            handleTabChange(null, 0)
        },
        [handleTabChange],
    )

    const handleResponsesTabClick = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            handleTabChange(null, 1)
        },
        [handleTabChange],
    )

    const isSubmitting = gstore.loading.is(PollModalStore.LoadingKeys.submit)

    const showResponsesTab = useMemo(
        () =>
            Boolean(props.id) &&
            props.mode !== EModalMode.Copy &&
            store.isEditing,
        [props.id, props.mode, store.isEditing],
    )

    const errorAlert = useMemo(() => {
        const error = store.form.error("generic")
        return error != null && error.trim() !== "" ? (
            <Alert severity="error">{error}</Alert>
        ) : null
    }, [store.form])

    const saveButton = useMemo(
        () =>
            !store.isReadOnly ? (
                <Button
                    type="submit"
                    variant="contained"
                    form="poll-form"
                    disabled={isSubmitting}
                >
                    {t`global.save`}
                </Button>
            ) : null,
        [store.isReadOnly, isSubmitting],
    )

    const tabsContainer = useMemo(
        () => (
            <TabContainer direction="row" spacing={2}>
                <StyledTab
                    type="button"
                    onClick={handleQuestionsTabClick}
                    active={activeTab === 0}
                    disabled={props.mode === EModalMode.View}
                >
                    {t`poll.tabs.questions`}
                </StyledTab>
                <StyledTab
                    type="button"
                    onClick={handleResponsesTabClick}
                    disabled={!showResponsesTab}
                    active={activeTab === 1}
                >
                    {t`poll.tabs.responses`}
                </StyledTab>
            </TabContainer>
        ),
        [
            props.mode,
            activeTab,
            handleQuestionsTabClick,
            handleResponsesTabClick,
            showResponsesTab,
        ],
    )

    // Now do conditional returns after all hooks are defined
    if (!initialized) {
        return <CenteredSpinner height="100vh" />
    }

    return (
        <>
            <ModalHeader>{saveButton}</ModalHeader>
            {isSubmitting && <CenteredSpinner />}
            <Stack spacing={2}>
                {tabsContainer}
                {activeTab === 0 ? (
                    <Form formId="poll-form" onSubmit={handleSubmit}>
                        {errorAlert}
                        <QuestionsTab />
                    </Form>
                ) : (
                    <ResponsesTab />
                )}
            </Stack>
        </>
    )
})

export const PollsModal = (props: IPollModalProps) => (
    <StoreProvider Store={PollModalStore}>
        <Modal {...props} />
    </StoreProvider>
)
