import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { ResponseStack } from "./styled"

import { FormPanel } from "src/components/FormPanel"
import { useStore } from "src/store/lib/useStore"
import { PollModalStore } from "src/modals/polls/store"
import { SummarySection } from "src/modals/polls/sections/Summary"
import { ResultsSection } from "src/modals/polls/sections/Results"
import { PollStatusChip } from "src/modals/polls/components/PollStatusChip"

export const ResponsesTab = observer(() => {
    const store = useStore(PollModalStore)
    const poll = store.poll

    // Return null if poll is not available
    if (poll == null) {
        return null
    }

    const totalVotes = poll.votes_count ?? 0
    const pollStatus = poll.status ?? "draft"
    const responseHeader = `${totalVotes} ${t`poll.responses`}`

    return (
        <ResponseStack>
            <FormPanel
                sections={[
                    {
                        header: responseHeader,
                        rightComponent: <PollStatusChip status={pollStatus} />,
                        content: <SummarySection poll={poll} />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`poll.results-section.header`,
                        content: <ResultsSection poll={poll} />,
                    },
                ]}
            />
        </ResponseStack>
    )
})
