import { t } from "@lingui/macro"
import { Stack, Typography } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { DateTimePicker } from "src/components/DateTimePicker"
import { AccessGroup } from "src/components/ModalAccessGroup/AccessGroup"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { PollModalStore } from "src/modals/polls/store"
import { PollSourceContext } from "src/modals/polls/types"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export const PollSettingsSection = observer(() => {
    const store = useStore(PollModalStore)
    const globalStore = useStore(GlobalStore)

    const ERROR_TRANSLATIONS: Record<string, string> = useMemo(
        () => ({
            "errors.invalid_date": t`poll.settings-section.invalid-date`,
            "errors.date_in_past": t`poll.settings-section.date-in-past`,
            "errors.required": t`errors.required`,
        }),
        [],
    )

    useEffect(() => {
        // When component mounts, if accessGroupId is undefined, set it to the current global value
        if (store.form.get("accessGroupId") === undefined) {
            store.setAccessGroupId(globalStore.session.accessGroup.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalStore.session.accessGroup.id])

    const handleDateChange = useCallback(
        (date: Date | null) => {
            store.form.set("endDate", date)
            // Validate only the date field
            store.validateEndDate()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [store.form],
    )

    const handleAccessGroupChange = useCallback(
        (id: number) => {
            store.form.set("accessGroupId", id)
        },
        [store.form],
    )

    const datePickerHelperText = useMemo(() => {
        const errorMsg = store.form.error("endDate")

        return errorMsg != null ? ERROR_TRANSLATIONS[errorMsg] : null
    }, [ERROR_TRANSLATIONS, store.form])

    const handleSegmentsChange = useCallback(
        (segments: number[]) => {
            store.form.set("segmentIds", segments)
        },
        [store.form],
    )

    return (
        <Stack spacing={2}>
            <DateTimePicker
                inputId={"endDate"}
                label={t`poll.settings-section.end-date-label`}
                value={store.form.get("endDate")}
                onChangePicker={handleDateChange}
                disabled={store.isReadOnly}
                minDate={new Date()}
                error={Boolean(store.form.error("endDate"))}
                helperText={datePickerHelperText}
            />
            <AccessGroup
                accessGroupId={store.form.get("accessGroupId")}
                errorMessage={store.form.error("accessGroupId") ?? ""}
                onChange={handleAccessGroupChange}
                disabled={store.isReadOnly}
            />
            {store.sourceContext === PollSourceContext.PollList && (
                <Stack spacing={1}>
                    <Typography variant="h6">{t`global.publish-to-label`}</Typography>
                    <SegmentPickerField
                        value={store.form.get("segmentIds") ?? []}
                        onChange={handleSegmentsChange}
                        accessGroupId={store.form.get("accessGroupId")}
                        disabled={store.isReadOnly}
                    />
                </Stack>
            )}
        </Stack>
    )
})
