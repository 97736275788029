import {
    FieldTypeDropdownKeys,
    FieldTypeDropdownValues,
    IFieldTypeDropdownItem,
} from "src/components/Inputs/types/IFieldTypeDropdown"
import { ShortRoundText } from "src/components/icons/ShortTextRound"
import { ParagraphRound } from "src/components/icons/ParagraphRound"
import { RadioButtonFilled } from "src/components/icons/RadioButtonFilled"
import { CheckBoxFilled } from "src/components/icons/CheckBoxFilled"
import { ArrowDropdownCircle } from "src/components/icons/ArrowDropdownCircle"
import { FileUpload } from "src/components/icons/FileUpload"

export const DROPDOWN_VALUES: IFieldTypeDropdownItem[] = [
    {
        name: FieldTypeDropdownValues.ShortAnswer,
        icon: <ShortRoundText />,
        value: FieldTypeDropdownKeys.ShortAnswer,
    },
    {
        name: FieldTypeDropdownValues.Paragraph,
        icon: <ParagraphRound />,
        value: FieldTypeDropdownKeys.Paragraph,
    },
    {
        name: FieldTypeDropdownValues.RadioButton,
        icon: <RadioButtonFilled />,
        value: FieldTypeDropdownKeys.RadioButton,
    },
    {
        name: FieldTypeDropdownValues.Checkbox,
        icon: <CheckBoxFilled />,
        value: FieldTypeDropdownKeys.Checkbox,
    },
    {
        name: FieldTypeDropdownValues.Dropdown,
        icon: <ArrowDropdownCircle />,
        value: FieldTypeDropdownKeys.Dropdown,
    },
    {
        name: FieldTypeDropdownValues.FileUpload,
        icon: <FileUpload />,
        value: FieldTypeDropdownKeys.FileUpload,
    },
]
