import { makeAutoObservable } from "mobx"
import React from "react"

import { PollSourceContext, IPollFormFields } from "./types/index"

import {
    PollAdminService,
    poll_CreatePollRequest,
    poll_UpdatePollRequest,
    avy_api_pkg_segment_PublishResourceRequest,
    poll_PollVoteOption,
    poll_PollForAdmin,
} from "src/api"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"
import { Channel } from "src/channel"
import { shouldPublishSegments } from "src/helpers/shouldPublishSegments"
import { EModalMode } from "src/types/modal/modal"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { FieldTypeDropdownKeys } from "src/components/Inputs/types/IFieldTypeDropdown"

export class PollModalStore {
    //#region Static Properties
    static Context = React.createContext<PollModalStore | null>(null)
    static LoadingKeys = createLoadingKeys(
        "submit",
        "delete",
        "close",
        "publish",
    )
    //#endregion

    //#region State Properties
    form = new FormFields<IPollFormFields>({
        question: "",
        name: "",
        options: ["", ""],
        endDate: null,
        questionType: FieldTypeDropdownKeys.RadioButton,
        accessGroupId: undefined,
        segmentIds: [],
    })

    private _sourceContext: PollSourceContext = PollSourceContext.PollList
    private _pollId?: number
    private _poll?: poll_PollForAdmin
    private _canEdit = true
    //#endregion

    //#region Computed Properties
    get isEditing() {
        return this.pollId !== undefined
    }

    get pollId(): number | undefined {
        return this._pollId
    }

    get poll(): poll_PollForAdmin | undefined {
        return this._poll
    }

    get isReadOnly(): boolean {
        return !this._canEdit
    }

    get sourceContext(): PollSourceContext {
        return this._sourceContext
    }

    setPollId(value: number | undefined) {
        this._pollId = value
    }

    setPoll(value: poll_PollForAdmin | undefined) {
        this._poll = value
    }

    setCanEdit(value: boolean) {
        this._canEdit = value
    }

    setSourceContext(value: PollSourceContext) {
        this._sourceContext = value
    }
    //#endregion

    constructor() {
        makeAutoObservable(this)
    }

    //#region Initialization Methods
    public async init(
        id?: string,
        sourceContext?: PollSourceContext,
        mode?: EModalMode,
    ) {
        sourceContext !== undefined && this.setSourceContext(sourceContext)

        if (id !== undefined) {
            const response = await PollAdminService.getV1AdminPoll({
                pollId: parseInt(id),
            })

            this.form.init({
                question: response.question ?? "",
                name: response.name ?? "",
                options: (response.vote_options ?? []).map(
                    (opt: poll_PollVoteOption) => opt.answer ?? "",
                ),
                endDate:
                    response.close_at !== undefined
                        ? new Date(response.close_at)
                        : null,
                questionType:
                    response.vote_type === "multiple"
                        ? FieldTypeDropdownKeys.Checkbox
                        : FieldTypeDropdownKeys.RadioButton,
                accessGroupId:
                    response.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                segmentIds: response.segment_ids ?? [],
            })

            this.setPollId(parseInt(id))
            // set store variables
            this.setPoll(response)
            // Determine if poll can be edited (draft or not published yet)
            this.setCanEdit(
                mode === EModalMode.Copy ||
                    !Boolean(response.poll_id) ||
                    response.status === "draft" ||
                    !Boolean(response.segment_ids?.length),
            )

            if (mode === EModalMode.Copy) {
                this.setPollId(undefined)
                this.form.set("name", `${response.name ?? ""} (Copy)`)
                this.form.set("segmentIds", [])
            }

            // check validation
            this.validate()
        }
    }
    //#endregion

    private async createPoll() {
        const request = this.getRequestData()
        const segmentIds = this.form.get("segmentIds")

        const response = await PollAdminService.postV1AdminPoll({
            request: request as poll_CreatePollRequest,
        })

        // set store data
        this.setPollId(response.poll_id)
        this.setPoll(response)
        if (this.pollId === undefined) {
            throw new Error("Failed to get poll ID from response")
        }

        // publish if needed
        if (shouldPublishSegments(segmentIds)) {
            await this.publish()
        }

        Channel.send({
            name: "repository/updated",
            payload: {
                repository: "poll",
                action: "create",
                item: {
                    id: response.poll_id ?? 0,
                    name: response.name ?? "",
                },
            },
        })
    }

    private async updatePoll(pollId: number) {
        const request = this.getRequestData()

        const response = await PollAdminService.putV1AdminPoll({
            pollId,
            request: request as poll_UpdatePollRequest,
        })

        this.setPoll(response)
        await this.publish()

        Channel.send({
            name: "repository/updated",
            payload: {
                repository: "poll",
                action: "update",
                item: {
                    id: pollId,
                    name: response.name ?? "",
                },
            },
        })
    }

    //#region CRUD Operations
    @loads(() => PollModalStore.LoadingKeys.submit)
    public async submit() {
        await this.form.catchErrors(async () => {
            this.validate()
            if (this.form.hasErrors()) return

            if (this.pollId !== undefined) {
                await this.updatePoll(this.pollId)
            } else {
                await this.createPoll()
            }
        })
    }

    @loads(() => PollModalStore.LoadingKeys.delete)
    public async delete() {
        if (this.pollId === undefined) return

        await PollAdminService.deleteV1AdminPoll({
            pollId: this.pollId,
        })
    }

    @loads(() => PollModalStore.LoadingKeys.close)
    public async close() {
        if (this.pollId === undefined) return

        await PollAdminService.putV1AdminPollClose({
            pollId: this.pollId,
        })
    }

    @loads(() => PollModalStore.LoadingKeys.publish)
    public async publish() {
        if (this.pollId === undefined) return

        const request: avy_api_pkg_segment_PublishResourceRequest = {
            published_in: this.form.get("segmentIds"), // TODO: This should be updated once we know what segments to publish to
        }

        await PollAdminService.putV1AdminPollPublish({
            pollId: this.pollId,
            request,
        })
    }
    //#endregion

    //#region Helper Methods
    private getRequestData(): poll_CreatePollRequest | poll_UpdatePollRequest {
        const { data } = this.form

        const baseRequest = {
            question: data.question,
            name: data.name,
            vote_options: data.options.map((text: string) => ({
                answer: text,
            })),
            close_at: data.endDate?.toISOString(),
            vote_type:
                data.questionType === FieldTypeDropdownKeys.Checkbox
                    ? "multiple"
                    : "single",
            status: "draft",
            access_group_id: data.accessGroupId ?? 1,
        }

        return this.pollId !== undefined
            ? (baseRequest as poll_UpdatePollRequest)
            : (baseRequest as poll_CreatePollRequest)
    }
    //#endregion

    //#region Validation Methods
    public validate() {
        this.form.clearErrors()

        if (!Boolean(this.form.get("question"))) {
            this.form.setError("question", "errors.required")
        }

        if (!Boolean(this.form.get("name"))) {
            this.form.setError("name", "errors.required")
        }

        if (!Boolean(this.form.get("questionType"))) {
            this.form.setError("questionType", "errors.required")
        }

        if (this.form.get("options").some((option) => !Boolean(option))) {
            this.form.setError("options", "errors.required")
        }

        // Use the specialized endDate validation method
        this.validateEndDate()

        const accessGroupId = this.form.get("accessGroupId")
        if (accessGroupId === undefined || accessGroupId === null) {
            this.form.setError("accessGroupId", "errors.required")
        }

        return !this.form.hasErrors()
    }

    // Add method to validate only the end date
    public validateEndDate() {
        // Clear just the endDate error
        this.form.clearError("endDate")

        // Date validation
        const endDate = this.form.get("endDate")
        if (endDate == null) {
            this.form.setError("endDate", "errors.required")
            return false
        } else if (!(endDate instanceof Date) || isNaN(endDate.getTime())) {
            // Check if it's a valid Date object
            this.form.setError("endDate", "errors.invalid_date")
            return false
        } else if (endDate < new Date()) {
            // Check if the date is in the past
            this.form.setError("endDate", "errors.date_in_past")
            return false
        }

        return true
    }
    //#endregion

    //#region Form Setters
    public setAccessGroupId(id: number | undefined) {
        this.form.set("accessGroupId", id)
    }
    //#endregion
}
