import { Tabs as MuiTabs, Theme, Stack } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledTabs = styled(MuiTabs)(({ theme }: { theme: Theme }) => ({
    width: "100%",
    "& .MuiTabs-flexContainer": {
        justifyContent: "space-evenly",
    },
    "& .MuiTab-root": {
        flex: 1,
        minWidth: "50%",
    },
    "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.info.main,
    },
    "& .MuiTab-textColorPrimary": {
        color: theme.palette.text.secondary,
        "&.Mui-selected": {
            color: theme.palette.info.main,
        },
    },
}))

export const StyledTab = styled("button", {
    shouldForwardProp: (props) => props !== "active",
})<{ active?: boolean }>(({ theme, active }) => ({
    border: "none",
    background: "none",
    cursor: "pointer",
    padding: "1rem",
    width: "100%",
    color: Boolean(active)
        ? theme.palette.info.main
        : theme.palette.text.secondary,
    fontWeight: Boolean(active) ? 500 : 400,
    borderBottom: Boolean(active)
        ? `2px solid ${theme.palette.info.main}`
        : "2px solid transparent",
    "&:hover": {
        color: theme.palette.info.main,
    },
    "&:disabled": {
        cursor: "not-allowed",
        opacity: 0.5,
    },
}))

export const TabContainer = styled(Stack)({
    borderBottom: 1,
    borderColor: "divider",
    width: "100%",
})
