import { IModalOptions } from "src/types/modal/modal-options"

export interface IModal {
    id: string
    Component: () => JSX.Element
    isClosing: boolean
    options: IModalOptions
    confirmOnOverlayClick?: () => boolean
}

export interface IPreviewModal {
    id: string
    Component: () => JSX.Element
    isPreviewClosing: boolean
}

export enum EModalMode {
    Copy = "Copy",
    Edit = "Edit",
    View = "View",
}
