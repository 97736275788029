import { styled } from "@mui/material/styles"
import { Stack } from "@mui/material"

export const StyledPollSummaryContainer = styled(Stack)({
    marginTop: "16px",
    flexDirection: "row",
    justifyContent: "space-between",
    "& > *": {
        marginRight: "32px", // equivalent to spacing={4}
    },
    "& > *:last-child": {
        marginRight: 0,
    },
})
