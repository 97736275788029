import { t } from "@lingui/macro"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { useCallback, useMemo } from "react"

import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { SegmentIdsCell } from "src/components/TableCells/SegmentIdsCell"
import { Time } from "src/components/Time"
import { IColumn } from "src/types/data-grid-pro"
import { ITemplateFeature } from "src/views/template-features/types/template-feature"

export const useGetTemplateFeatureColumns = (): IColumn<ITemplateFeature>[] => {
    const renderSegmentIdsCell = useCallback(
        (params: GridRenderCellParams) => (
            <SegmentIdsCell segmentNames={params.value} />
        ),
        [],
    )

    const renderAccessTypeCell = useCallback(
        (params: GridRenderCellParams) => (
            <AccessTypeHeader accessType={params.value} />
        ),
        [],
    )

    const renderDateCell = useCallback((params: GridRenderCellParams) => {
        return <Time date={params.value} />
    }, [])

    return useMemo(
        () => [
            {
                field: "id",
                headerName: t`template-features.id`,
                filterable: false,
                sortable: false,
                width: 150,
            },
            {
                field: "name",
                headerName: t`template-features.name`,
                flex: 1,
            },
            {
                field: "description",
                headerName: t`template-features.description`,
                flex: 2,
            },
            {
                field: "segment_names",
                headerName: t`template-features.segments`,
                filterable: false,
                sortable: false,
                flex: 1,
                renderCell: renderSegmentIdsCell,
            },
            {
                field: "access_type",
                headerName: t`template-features.access-type`,
                filterable: false,
                sortable: false,
                flex: 1,
                renderCell: renderAccessTypeCell,
            },
            {
                field: "created_at",
                headerName: t`template-features.created-at`,
                filterable: false,
                flex: 1,
                renderCell: renderDateCell,
                type: "date",
            },
            {
                field: "updated_at",
                headerName: t`template-features.updated-at`,
                filterable: false,
                flex: 1,
                renderCell: renderDateCell,
                type: "date",
            },
        ],
        [renderAccessTypeCell, renderSegmentIdsCell, renderDateCell],
    )
}
