import { styled } from "@mui/material/styles"
import { Stack } from "@mui/material"

export const ResponseStack = styled(Stack)({
    "& > *": {
        marginBottom: "24px", // equivalent to spacing={3}
    },
    "& > *:last-child": {
        marginBottom: 0,
    },
})
