import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { TemplateFeatureSettingsSection } from "./sections/Settings"
import { TemplateFeatureModalStore } from "./store"

import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { useInitializer } from "src/lib/initializer"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { ITemplateFeatureModalProps } from "src/modals/template-features/types/template-feature-modal-props"
import { FormPanel } from "src/components/FormPanel"
import { SegmentPickerField } from "src/components/SegmentPickerField"

const Modal = observer(({ id, mode }: ITemplateFeatureModalProps) => {
    const store = useStore(TemplateFeatureModalStore)
    const globalStore = useStore(GlobalStore)

    useCloseConfirmationForForm(store.formFields)

    const initialized = useInitializer(async () => {
        await store.init(id, globalStore.session.accessGroupId, mode)
    }, [id, globalStore.session.accessGroupId])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            await store.handleSubmit()

            if (!store.formFields.hasErrors()) {
                globalStore.modals.pop()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const handleSegmentsChange = useCallback(
        (segments: number[]) => {
            store.formFields.set("segment_ids", segments)
        },
        [store.formFields],
    )

    if (!initialized) return <CenteredSpinner height="45vh" />

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={store.isLoading || !store.formFields.getIsDirty()}
                    data-testid="submit-template-feature-form"
                >
                    {t`global.save`}
                </Button>
            </ModalHeader>

            {Boolean(store.formFields.error("generic")) && (
                <Alert severity="error">
                    {store.formFields.error("generic")}
                </Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`template-features.settings-section`,
                        content: <TemplateFeatureSettingsSection />,
                    },
                    {
                        header: t`template-features.publish-settings-section`,
                        content: (
                            <SegmentPickerField
                                value={
                                    store.formFields.get("segment_ids") ?? []
                                }
                                onChange={handleSegmentsChange}
                                accessGroupId={store.formFields.get(
                                    "access_group_id",
                                )}
                                disabled={store.isReadOnly}
                            />
                        ),
                    },
                ]}
            />
        </Form>
    )
})

export const TemplateFeatureModal = (props: ITemplateFeatureModalProps) => (
    <StoreProvider Store={TemplateFeatureModalStore}>
        <Modal {...props} />
    </StoreProvider>
)
