import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { QuestionStack } from "./styled"

import { FormPanel } from "src/components/FormPanel"
import { QuestionsConfigSection } from "src/modals/polls/sections/QuestionConfig"
import { PollSettingsSection } from "src/modals/polls/sections/Settings"

export const QuestionsTab = observer(() => {
    return (
        <QuestionStack spacing={2}>
            <FormPanel
                sections={[
                    {
                        header: t`poll.settings-section.header`,
                        content: <PollSettingsSection />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`poll.question-config.header`,
                        content: <QuestionsConfigSection />,
                    },
                ]}
            />
        </QuestionStack>
    )
})
