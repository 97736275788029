import { observer } from "mobx-react"
import { Alert, Button, Stack, useTheme } from "@mui/material"
import { useCallback, useEffect, useMemo } from "react"
import { t } from "@lingui/macro"

import { MainContainer } from "./styled"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { CommunityPostsAndCommentsEditStore } from "src/modals/community-posts-and-comments-edit/store"
import { FormPanel } from "src/components/FormPanel"
import { SettingsSection } from "src/modals/community-posts-and-comments-edit/sections/SettingsSection"
import { MessageSection } from "src/modals/community-posts-and-comments-edit/sections/MessageSection"
import { ImageSection } from "src/modals/community-posts-and-comments-edit/sections/ImageSection"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { Preview } from "src/components/icons/Preview"
import { Icon } from "src/components/icons"
import { AvyPreview } from "src/components/Preview"
import { useGetAuthors } from "src/shared/community-posts-and-comments/hooks/useGetAuthors"
import { community_PostWithAdminDetails } from "src/api"

interface IProps {
    id?: number
    originalPost?: community_PostWithAdminDetails
    newPollId?: number
    isDuplicate?: boolean
}

const View = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const store = useStore(CommunityPostsAndCommentsEditStore)
    const theme = useTheme()
    const { authors } = useGetAuthors()

    useEffect(() => {
        ;(async () => {
            await store.init(
                authors,
                props.id,
                gstore.session.accessGroupId,
                props.originalPost,
                props.newPollId,
                props.isDuplicate,
            )
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        store,
        authors,
        props.id,
        props.originalPost,
        props.newPollId,
        props.isDuplicate,
    ])

    useCloseConfirmationForForm(store.form)

    // Memoize expensive computations
    const communityNames = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!store.communities || !store.form.data.communityIds) return ""

        return store.communities
            .filter((community) =>
                store.form.data.communityIds.includes(community.id),
            )
            .map((community) => community.name)
            .join(", ")
    }, [store.communities, store.form.data.communityIds])

    const onPreviewClick = useCallback(() => {
        trackModuleEvent("Preview community post", {
            [MixpanelProperties.EventDefinition]: "User previews a post",
            [MixpanelProperties.PostID]: store.form.get("id"),
            [MixpanelProperties.PostText]: store.form.get("text"),
            [MixpanelProperties.PreviewedBy]: gstore.session.user?.name,
        })
        gstore.modals.togglePreview(() => (
            <AvyPreview details={store.getDetailsPreviewUrl} />
        ))
    }, [
        gstore.modals,
        gstore.session.user?.name,
        store.form,
        store.getDetailsPreviewUrl,
    ])

    const handleSubmit = useCallback(async () => {
        const isScheduled = store.form.get("scheduledAt") != null
        const scheduledFor = store.form.get("scheduledAt")?.toDateString()

        trackModuleEvent("Publish community post", {
            [MixpanelProperties.EventDefinition]:
                "When the user finalises the post",
            [MixpanelProperties.CommunityCount]:
                store.form.data.communityIds.length,
            [MixpanelProperties.CommunityName]: communityNames,
            [MixpanelProperties.Scheduled]: isScheduled ? "Yes" : "No",
            [MixpanelProperties.ScheduledFor]: scheduledFor,
            [MixpanelProperties.CreatedDate]: new Date().toISOString(),
        })

        await store.submit()

        if (!store.form.hasErrors()) {
            gstore.modals.popPreview()
            gstore.modals.pop()
        }
    }, [store, gstore.modals, communityNames])

    if (
        gstore.loading.is(
            CommunityPostsAndCommentsEditStore.LoadingKeys.init,
        ) ||
        !store.initialized
    ) {
        return <CenteredSpinner />
    }
    const isSubmitDisabled =
        gstore.loading.is(
            CommunityPostsAndCommentsEditStore.LoadingKeys.submit,
        ) ||
        store.form.get("accessType") === "READ" ||
        store.form.getIsDirty() === false ||
        store.form.data.communityIds.length === 0

    const isPreviewEnabled =
        store.form.data.communityIds.length !== 0 &&
        (store.form.get("text").trim() !== "" ||
            store.form.get("communityIds").length > 0 ||
            store.form.get("images").some((image) => image !== null))

    return (
        <MainContainer>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <Stack direction="row" spacing={1}>
                        <Button
                            startIcon={
                                <Icon
                                    icon={<Preview />}
                                    size={20}
                                    color={theme.palette.info.main}
                                />
                            }
                            disabled={!isPreviewEnabled}
                            variant="contained"
                            color="info"
                            onClick={onPreviewClick}
                            data-testid="preview-button"
                        >
                            {t`global.preview-button`}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={isSubmitDisabled}
                        >
                            {store.isEditing
                                ? t`community-post-detail-modal.save-button`
                                : t`community-post-detail-modal.publish-button`}
                        </Button>
                    </Stack>
                </ModalHeader>
                {Boolean(store.form.error("generic")) && (
                    <Alert severity="error">
                        {store.form.error("generic")}
                    </Alert>
                )}
                <FormPanel
                    sections={[
                        {
                            header: t`community-post-detail-modal.settings-section.header`,
                            content: <SettingsSection />,
                        },
                    ]}
                />
                <FormPanel
                    sections={[
                        {
                            header: t`community-post-detail-modal.message-section.header`,
                            subHeader: t`community-post-detail-modal.message-section.sub-header`,
                            content: <MessageSection />,
                        },
                        {
                            header: t`community-post-detail-modal.images-section.header`,
                            subHeader: t`community-post-detail-modal.images-section.sub-header`,
                            content: <ImageSection />,
                        },
                    ]}
                />
            </Form>
        </MainContainer>
    )
})

export const CommunityPostsAndCommentsEditModal = observer((props: IProps) => (
    <StoreProvider Store={CommunityPostsAndCommentsEditStore}>
        <View {...props} />
    </StoreProvider>
))
