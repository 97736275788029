import {
    DeleteOutlineRounded,
    Edit,
    FactCheckRounded,
    BarChart,
} from "@mui/icons-material"
import { Button, Typography, Stack } from "@mui/material"
import { useCallback, useMemo } from "react"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { GlobalStore } from "src/store"

import { PollActionControlStore } from "src/components/PollActionControl/store"
import { useStore } from "src/store/lib/useStore"
import { PollsModal } from "src/modals/polls"
import { IPollActionControlProps } from "src/components/PollActionControl/types"
import { ConfirmModal } from "src/modals/confirm"
import { EModalMode } from "src/types/modal/modal"
import { PollSourceContext } from "src/modals/polls/types"
import {
    BUTTON_COMMON_PROPS,
    MAX_BUTTON_WIDTH,
} from "src/components/PollActionControl/constants/constants"

export const PollsInformation = observer(
    ({ pollId, onPollIdChange, mode }: IPollActionControlProps) => {
        const gstore = useStore(GlobalStore)
        const store = useStore(PollActionControlStore)

        const handleEditPoll = useCallback(() => {
            gstore.modals.open(
                () => (
                    <PollsModal
                        id={store.currentPollId ?? undefined}
                        mode={mode}
                        sourceContext={PollSourceContext.Embedded}
                    />
                ),
                {
                    variant: "right-slide-in",
                },
            )
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [pollId])

        const handleRemovePoll = useCallback(() => {
            gstore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={async () => {
                            await store.deletePoll()
                            if (onPollIdChange != null) {
                                onPollIdChange(null)
                            }
                        }}
                        title={t`poll-action-control.delete-confirm-modal.title`}
                        content={t`poll-action-control.delete-confirm-modal.content`}
                    />
                ),
                { variant: "slide-up-w600" },
            )
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [onPollIdChange])

        const handleViewPollResults = useCallback(() => {
            gstore.modals.open(() => (
                <PollsModal
                    id={store.currentPollId ?? undefined}
                    tabId={1}
                    mode={mode}
                    sourceContext={PollSourceContext.Embedded}
                />
            ))
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [mode, store.currentPollId])

        const renderActionButtons = useMemo(() => {
            if (mode === EModalMode.View) {
                return (
                    <Button
                        {...BUTTON_COMMON_PROPS}
                        startIcon={<BarChart />}
                        onClick={handleViewPollResults}
                        size="small"
                    >
                        {t`poll-button.view-poll-results`}
                    </Button>
                )
            }

            return (
                <Stack direction="row" spacing={1}>
                    <Button
                        {...BUTTON_COMMON_PROPS}
                        startIcon={<Edit />}
                        onClick={handleEditPoll}
                        size="small"
                    >
                        {t`poll-button.edit-poll`}
                    </Button>
                    <Button
                        variant="text"
                        color="error"
                        startIcon={<DeleteOutlineRounded />}
                        onClick={handleRemovePoll}
                        size="small"
                        sx={{ maxWidth: MAX_BUTTON_WIDTH }}
                    >
                        {t`poll-button.remove-poll`}
                    </Button>
                </Stack>
            )
        }, [handleEditPoll, handleRemovePoll, handleViewPollResults, mode])

        return (
            <Stack spacing={1}>
                <Typography variant="subtitle1">
                    {t`poll-button.added-poll-label`}
                </Typography>
                <Stack direction="row" spacing={1}>
                    <FactCheckRounded />
                    <Typography variant="subtitle1" fontWeight="bold">
                        {store.pollDetails?.question ?? t`poll.unnamed-poll`}
                    </Typography>
                </Stack>
                {renderActionButtons}
            </Stack>
        )
    },
)
