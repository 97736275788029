/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { templatefeature_CreateTemplateFeatureRequest } from "../models/templatefeature_CreateTemplateFeatureRequest"
import type { templatefeature_PublishTemplateFeatureRequest } from "../models/templatefeature_PublishTemplateFeatureRequest"
import type { templatefeature_TemplateFeature } from "../models/templatefeature_TemplateFeature"
import type { templatefeature_TemplateFeatureListRequest } from "../models/templatefeature_TemplateFeatureListRequest"
import type { templatefeature_TemplateFeatureListResponse } from "../models/templatefeature_TemplateFeatureListResponse"
import type { templatefeature_UpdateTemplateFeatureRequest } from "../models/templatefeature_UpdateTemplateFeatureRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class TemplateFeatureAdminService {
    /**
     * Create template feature
     * Create a new template feature
     * @returns templatefeature_TemplateFeature OK
     * @throws ApiError
     */
    public static postV1AdminTemplatefeature({
        request,
    }: {
        /** Create template feature request **/
        request: templatefeature_CreateTemplateFeatureRequest
    }): CancelablePromise<templatefeature_TemplateFeature> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/templatefeature",
            body: request,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        })
    }

    /**
     * List template features
     * Get a list of template features
     * @returns templatefeature_TemplateFeatureListResponse OK
     * @throws ApiError
     */
    public static postV1AdminTemplatefeatureList({
        request,
    }: {
        /** List template features request **/
        request: templatefeature_TemplateFeatureListRequest
    }): CancelablePromise<templatefeature_TemplateFeatureListResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/templatefeature/list",
            body: request,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        })
    }

    /**
     * Get template feature
     * Get a single template feature by ID
     * @returns templatefeature_TemplateFeature OK
     * @throws ApiError
     */
    public static getV1AdminTemplatefeature({
        id,
    }: {
        /** Template feature ID **/
        id: number
    }): CancelablePromise<templatefeature_TemplateFeature> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/templatefeature/{id}",
            path: {
                id: id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        })
    }

    /**
     * Update template feature
     * Update an existing template feature
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminTemplatefeature({
        id,
        request,
    }: {
        /** Template feature ID **/
        id: number
        /** Update template feature request **/
        request: templatefeature_UpdateTemplateFeatureRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/templatefeature/{id}",
            path: {
                id: id,
            },
            body: request,
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        })
    }

    /**
     * Delete template feature
     * Delete a template feature by ID
     * @returns void
     * @throws ApiError
     */
    public static deleteV1AdminTemplatefeature({
        id,
    }: {
        /** Template feature ID **/
        id: number
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/templatefeature/{id}",
            path: {
                id: id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        })
    }

    /**
     * Publish template feature
     * Publish a template feature to specified segments
     * @returns void
     * @throws ApiError
     */
    public static putV1AdminTemplatefeaturePublish({
        id,
        request,
    }: {
        /** Template feature ID **/
        id: number
        /** Publish template feature request **/
        request: templatefeature_PublishTemplateFeatureRequest
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/templatefeature/{id}/publish",
            path: {
                id: id,
            },
            body: request,
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        })
    }
}
