import { Tooltip, styled, Box, IconButton } from "@mui/material"

const StyledTooltip = styled(Tooltip)(() => ({
    marginTop: 0,
}))

const TooltipContainer = styled(Box)(() => ({
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "14px",
    letterSpacing: "0px",
}))

const DefaultValueContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
}))

const DefaultValueText = styled(Box)(() => ({
    fontStyle: "italic",
}))

const CopyButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.common.white,
    padding: "2px",
    marginLeft: "4px",
    fontSize: "1rem",
}))

export {
    StyledTooltip,
    TooltipContainer,
    DefaultValueContainer,
    DefaultValueText,
    CopyButton,
}
