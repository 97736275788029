import { Typography, useTheme, Box } from "@mui/material"
import { memo, useCallback, useMemo } from "react"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

import {
    StyledTooltip,
    TooltipContainer,
    DefaultValueContainer,
    DefaultValueText,
    CopyButton,
} from "src/components/Tooltips/TooltipWithIcon/styled"
import { Icon } from "src/components/icons"
import { Alert16 } from "src/components/icons/Alert16"
import { ITooltipWithIconProps } from "src/types/tooltip/tooltipWithIconProps"

export const TooltipWithIcon = memo(
    ({ icon, onClick, tooltipText, defaultValue }: ITooltipWithIconProps) => {
        const theme = useTheme()

        const _icon = useMemo(
            () => icon ?? <Alert16 width="20px" height="20px" />,
            [icon],
        )

        const handleCopyClick = useCallback(
            async (e: React.MouseEvent) => {
                e.stopPropagation()
                if (defaultValue !== undefined) {
                    const stringToCopy =
                        typeof defaultValue === "object"
                            ? JSON.stringify(defaultValue)
                            : String(defaultValue)
                    await navigator.clipboard.writeText(stringToCopy)
                }
            },
            [defaultValue],
        )

        const defaultValueStr = useMemo(() => {
            if (defaultValue === undefined) return ""
            return typeof defaultValue === "object"
                ? JSON.stringify(defaultValue)
                : String(defaultValue)
        }, [defaultValue])

        const tooltipContent = useMemo(() => {
            const displayText = tooltipText ?? ""

            if (defaultValue === undefined) {
                return displayText
            }

            return (
                <TooltipContainer>
                    <Box>{displayText}</Box>
                    <DefaultValueContainer>
                        <DefaultValueText>
                            Default value: {defaultValueStr}
                            <CopyButton size="small" onClick={handleCopyClick}>
                                <ContentCopyIcon fontSize="inherit" />
                            </CopyButton>
                        </DefaultValueText>
                    </DefaultValueContainer>
                </TooltipContainer>
            )
        }, [tooltipText, defaultValue, defaultValueStr, handleCopyClick])

        return (
            <StyledTooltip title={tooltipContent} arrow onClick={onClick}>
                <Typography sx={{ marginTop: "16px" }}>
                    <Icon icon={_icon} color={theme.palette.grey[700]} />
                </Typography>
            </StyledTooltip>
        )
    },
)
