import { observer } from "mobx-react"
import { Box, IconButton } from "@mui/material"
import { RadioButtonChecked, CheckBox } from "@mui/icons-material"
import { t } from "@lingui/macro"
import { useMemo } from "react"

import { IPollOptionsSection } from "./types"

import { ModalTextField } from "src/components/ModalTextField"
import { Cross16 } from "src/components/icons/Cross16"
import { StyledPollOptionStack } from "src/modals/polls/components/PollOptions/styled"
import { FieldTypeDropdownKeys } from "src/components/Inputs/types/IFieldTypeDropdown"

export const PollOptionsSection = observer(
    ({
        type,
        value,
        onChange,
        onRemove,
        disabled,
        errorMessage,
        showRemoveButton,
        index,
    }: IPollOptionsSection) => {
        const icon = useMemo(
            () =>
                type === FieldTypeDropdownKeys.RadioButton ? (
                    <RadioButtonChecked color="disabled" />
                ) : (
                    <CheckBox color="disabled" />
                ),
            [type],
        )

        return (
            <StyledPollOptionStack key={index}>
                {icon}
                <Box sx={{ flex: 1 }}>
                    <ModalTextField
                        variant="default"
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        error={Boolean(errorMessage)}
                        helperText={errorMessage}
                        label={t`poll.question-config.option-label ${
                            index + 1
                        }`}
                    />
                </Box>
                {Boolean(showRemoveButton) && (
                    <IconButton
                        size="small"
                        onClick={onRemove}
                        color="inherit"
                        disabled={disabled}
                    >
                        <Cross16 />
                    </IconButton>
                )}
            </StyledPollOptionStack>
        )
    },
)
