import { FieldTypeDropdownKeys } from "src/components/Inputs/types/IFieldTypeDropdown"

export const POLL_CONSTANTS = {
    MIN_OPTIONS: 2,
    DEFAULT_ICON_SIZE: 20,
    MAX_QUESTION_LENGTH: 100,
}

export const POLL_FIELD_TYPE_DROPDOWN_OPTIONS = [
    FieldTypeDropdownKeys.RadioButton,
    FieldTypeDropdownKeys.Checkbox,
]
