import { t } from "@lingui/macro"
import { Button } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useCallback, useEffect } from "react"

import { PollActionControlStore } from "./store"

import { IPollActionControlProps } from "./types"

import { PollsModal } from "src/modals/polls"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"

import { PollsInformation } from "src/components/PollActionControl/subComponents/PollsInformation"
import { useInitializer } from "src/lib/initializer"
import { PollSourceContext } from "src/modals/polls/types"
import { BUTTON_COMMON_PROPS } from "src/components/PollActionControl/constants/constants"

const Component = observer(
    ({ pollId, onPollIdChange, mode }: IPollActionControlProps) => {
        const gstore = useStore(GlobalStore)
        const store = useStore(PollActionControlStore)

        const initialized = useInitializer(async () => {
            await store.initialize(pollId)
        }, [pollId])

        useEffect(() => {
            return () => store.dispose()

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const isLoading =
            gstore.loading.is(PollActionControlStore.LoadingKeys.fetch) ||
            gstore.loading.is(PollActionControlStore.LoadingKeys.delete) ||
            !initialized

        const handleAddPoll = useCallback(() => {
            gstore.modals.open(
                () => (
                    <PollsModal
                        mode={mode}
                        sourceContext={PollSourceContext.Embedded}
                    />
                ),
                {
                    variant: "right-slide-in",
                    onClose: () => {
                        if (onPollIdChange != null) {
                            onPollIdChange(store.currentPollId)
                        }
                    },
                },
            )
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [onPollIdChange])

        if (isLoading) {
            return (
                <Button {...BUTTON_COMMON_PROPS} disabled>
                    {t`global.loading`}
                </Button>
            )
        } else if (store.pollDetails != null) {
            return (
                <PollsInformation
                    pollId={pollId}
                    onPollIdChange={onPollIdChange}
                    mode={mode}
                />
            )
        } else {
            return (
                <Button {...BUTTON_COMMON_PROPS} onClick={handleAddPoll}>
                    + {t`poll-button.add-poll`}
                </Button>
            )
        }
    },
)

export const PollActionControl = (props: IPollActionControlProps) => (
    <StoreProvider Store={PollActionControlStore}>
        <Component {...props} />
    </StoreProvider>
)
