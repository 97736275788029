/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_segment_PublishResourceRequest } from "../models/avy_api_pkg_segment_PublishResourceRequest"
import type { poll_CreatePollRequest } from "../models/poll_CreatePollRequest"
import type { poll_PollForAdmin } from "../models/poll_PollForAdmin"
import type { poll_PollListRequest } from "../models/poll_PollListRequest"
import type { poll_PollListResponse } from "../models/poll_PollListResponse"
import type { poll_UpdatePollRequest } from "../models/poll_UpdatePollRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class PollAdminService {
    /**
     * Create poll
     * Create a new poll
     * @returns poll_PollForAdmin OK
     * @throws ApiError
     */
    public static postV1AdminPoll({
        request,
    }: {
        /** Request body poll **/
        request: poll_CreatePollRequest
    }): CancelablePromise<poll_PollForAdmin> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/poll",
            body: request,
            errors: {
                400: `Bad Request`,
            },
        })
    }

    /**
     * List polls
     * Get a list of polls
     * @returns poll_PollListResponse OK
     * @throws ApiError
     */
    public static postV1AdminPollList({
        request,
    }: {
        /** List polls request **/
        request: poll_PollListRequest
    }): CancelablePromise<poll_PollListResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/poll/list",
            body: request,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        })
    }

    /**
     * Get poll
     * Get a single poll by ID
     * @returns poll_PollForAdmin OK
     * @throws ApiError
     */
    public static getV1AdminPoll({
        pollId,
    }: {
        /** Poll ID **/
        pollId: number
    }): CancelablePromise<poll_PollForAdmin> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/poll/{poll_id}",
            path: {
                poll_id: pollId,
            },
        })
    }

    /**
     * Update poll
     * Update an existing poll
     * @returns poll_PollForAdmin OK
     * @throws ApiError
     */
    public static putV1AdminPoll({
        pollId,
        request,
    }: {
        /** Poll ID **/
        pollId: number
        /** Request body poll **/
        request: poll_UpdatePollRequest
    }): CancelablePromise<poll_PollForAdmin> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/poll/{poll_id}",
            path: {
                poll_id: pollId,
            },
            body: request,
        })
    }

    /**
     * Delete poll
     * Delete a poll by ID
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminPoll({
        pollId,
    }: {
        /** Poll ID **/
        pollId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/poll/{poll_id}",
            path: {
                poll_id: pollId,
            },
        })
    }

    /**
     * Close poll
     * Close a poll by ID
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminPollClose({
        pollId,
    }: {
        /** Poll ID **/
        pollId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/poll/{poll_id}/close",
            path: {
                poll_id: pollId,
            },
        })
    }

    /**
     * Publish poll
     * Publish a poll to specified segments
     * @returns void
     * @throws ApiError
     */
    public static putV1AdminPollPublish({
        pollId,
        request,
    }: {
        /** Poll ID **/
        pollId: number
        /** Request body to set where poll is published **/
        request: avy_api_pkg_segment_PublishResourceRequest
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/poll/{poll_id}/publish",
            path: {
                poll_id: pollId,
            },
            body: request,
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        })
    }
}
