import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { TemplateFeatureAdminService } from "src/api"
import { CustomDataHelper } from "src/api/_custom/services/DataHelper"
import { Channel } from "src/channel"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { parseDate } from "src/lib/date"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { reportError } from "src/lib/report"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"
import { ITemplateFeature } from "src/views/template-features/types/template-feature"

export class TemplateFeatureStore {
    //#region initialization
    static Context = React.createContext<TemplateFeatureStore | null>(null)
    static LoadingKeys = createLoadingKeys("loading")

    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    _segments: number[] = []

    private repositoryUpdatesListenerDisposer?: () => void

    templateFeatures = new Pagination(
        async (params) => {
            const response =
                await TemplateFeatureAdminService.postV1AdminTemplatefeatureList(
                    {
                        request: {
                            filter: params.advanceQuery,
                            page_number: params.page,
                            page_size: params.pageSize,
                            ...CustomDataHelper.PrepareVisibilityData(this),
                        },
                    },
                )

            const items: ITemplateFeature[] = (
                response.template_features ?? []
            ).map((feature) => ({
                id: feature.id ?? -1,
                name: feature.name ?? "",
                access_group_id: feature.access_group_id ?? -1,
                created_at: parseDate(feature.created_at),
                description: feature.description ?? "",
                segment_ids: feature.segment_ids ?? [],
                updated_at: parseDate(feature.updated_at),
                segment_names: feature.segment_names ?? [],
                access_type: feature.access_type ?? "NONE",
            }))

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        {
            loadingKey: TemplateFeatureStore.LoadingKeys.loading,
        },
    )

    constructor() {
        makeAutoObservable(this)
    }
    //#endregion

    //#region getters
    get segments() {
        return this._segments
    }

    private get accessGroupId() {
        return this._accessGroupId
    }
    //#endregion

    //#region setters
    private setSegments(segments: number[]) {
        this._segments = segments
    }

    private setAccessGroup(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }
    //#endregion

    //#region store operations
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this.listenToRepositoryUpdates()
        this.setAccessGroup(accessGroupId)
        try {
            await this.templateFeatures.loadInitialPage(advanceQuery)
        } catch (error) {
            reportError(t`template-features.init-fail`, error)
        }
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    async loadSegments(segments: number[], advanceQuery?: IAdvanceQueryModel) {
        this.setSegments(segments)
        await this.templateFeatures.loadInitialPage(advanceQuery)
    }

    deleteItem = async (id: number) => {
        try {
            await TemplateFeatureAdminService.deleteV1AdminTemplatefeature({
                id,
            })
        } catch (error) {
            reportError(t`template-features.delete-item-fail`, error)
        } finally {
            await this.templateFeatures.reload()
        }
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.templateFeatures.loadAdvanceQuery(advanceQuery)
    }

    private listenToRepositoryUpdates() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "template-feature"
                ) {
                    await this.templateFeatures.reload()
                }
            },
        )
    }
    //#endregion
}
