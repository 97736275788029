import { Box, Stack, Typography } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { StyledPollProgressBar, StyledPollOptionHeader } from "./styled"

import { IPollSectionProps } from "src/modals/polls/types/index"

export const ResultsSection = observer(({ poll }: IPollSectionProps) => {
    const totalVotes = poll.votes_count ?? 0

    const renderVoteOption = useCallback(
        (option: { answer?: string; votes?: number }) => {
            const votes = option.votes ?? 0
            const percentage = totalVotes > 0 ? (votes / totalVotes) * 100 : 0

            return (
                <Box sx={{ mb: 2 }} key={option.answer}>
                    <StyledPollOptionHeader>
                        <Typography variant="body1" marginBottom={0.5}>
                            {option.answer}
                        </Typography>
                        <Stack direction="row" spacing={2}>
                            <Typography
                                variant="subtitle2"
                                color="text.secondary"
                            >
                                {votes} votes
                            </Typography>
                            <Typography
                                variant="body1"
                                color={(theme) =>
                                    theme.palette.utility.blue.dark
                                }
                            >
                                {percentage.toFixed(0)}%
                            </Typography>
                        </Stack>
                    </StyledPollOptionHeader>
                    <StyledPollProgressBar
                        variant="determinate"
                        value={percentage}
                    />
                </Box>
            )
        },
        [totalVotes],
    )

    return (
        <Box>
            <Typography sx={{ mb: 2 }} variant="body1">
                {poll.question}
            </Typography>
            <Stack spacing={2}>
                {(poll.vote_options ?? []).map(renderVoteOption)}
            </Stack>
        </Box>
    )
})
