import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import {
    avy_api_pkg_admin_domain_ResourceAccessType,
    community_Author,
    community_CommentOnPost,
    community_PostWithAdminDetailsV2,
    CommunityAdminService,
} from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { getAuthorName } from "src/lib/community"
import { parseDate } from "src/lib/date"
import { persistFiles } from "src/lib/file"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { reportSuccess, reportUnhandledApiError } from "src/lib/report"
import {
    ICommentFormFields,
    IEditCommentFormFields,
} from "src/modals/community-posts-and-comments/types"
import {
    IAuthor,
    IComment,
    ILikeAuthor,
    IPost,
    IPostAndCommentsItemType,
} from "src/types/community-posts-and-comments/communityPostsAndComments"

const MAX_IMAGES = 3

export class CommunityPostsAndCommentsStore implements IDisposable {
    static Context = React.createContext<CommunityPostsAndCommentsStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys(
        "init",
        "submit-comment",
        "delete-post",
        "delete-comment",
        "update-comment",
        "like-comment",
        "like-post",
        "block-unblock",
        "reload",
    )
    private repositoryChangeListenerDisposer?: () => void
    private _post: IPost | null = null
    private editCommentForm: FormFields<IEditCommentFormFields> | null = null

    initialized = false
    comments: IComment[] = []
    authors: IAuthor[] = []

    commentForm = new FormFields<ICommentFormFields>({
        authorId: null,
        images: [],
        text: "",
    })

    currentPostId: number = 0
    allPostIds: number[] = []
    currentIndex: number = -1

    constructor() {
        makeAutoObservable(this)
        this.listenToRepositoryChanges()
    }

    dispose() {
        this.repositoryChangeListenerDisposer?.()
    }

    canEditPost(adminId?: number) {
        return (
            this.post.authorObjectType === "admin_user" &&
            this.post.authorObjectId === adminId
        )
    }

    canEditComment(commentId: number, adminId?: number) {
        const comment = this.comments.find(
            (comment) => comment.id === commentId,
        )
        if (comment == null) {
            return false
        }

        return (
            comment.authorObjectType === "admin_user" &&
            comment.authorObjectId === adminId
        )
    }

    canBlockUnblockTenant() {
        return this.post.authorObjectType === "tenant"
    }

    isWriteAccess() {
        return this.post.accessType === "WRITE"
    }

    get post(): IPost {
        if (this._post == null) {
            throw new Error("post not initialized")
        }

        return this._post
    }

    @loads(() => CommunityPostsAndCommentsStore.LoadingKeys.init)
    async init(id: number, authors: IAuthor[]) {
        await this.loadPost(id)
        this.setAuthors(authors)
        this.setInitialized()
        this.setInitialAuthor()
    }

    @loads(() => CommunityPostsAndCommentsStore.LoadingKeys["submit-comment"])
    async submitCommentForm() {
        try {
            this.commentForm.clearErrors()
            this.validate()
            if (this.commentForm.hasErrors()) {
                return
            }

            await this.commentForm.catchErrors(async () => {
                const images = await persistFiles(
                    this.commentForm.get("images"),
                    "image",
                )
                await CommunityAdminService.postV1AdminCommunityPostComment({
                    postId: this.post.id,
                    request: {
                        author_name: this.getAuthorNameFromForm(),
                        image_urls: images.map((image) => image.url),
                        text: this.commentForm.get("text"),
                    },
                })

                await this.reloadPost()

                this.resetCommentForm()

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "community-comments",
                        action: "create",
                    },
                })
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    appendImage(image: ILocalFile) {
        const images = [...this.commentForm.get("images")]
        this.commentForm.set("images", [...images, image])
    }

    deleteImageAtIndex(index: number) {
        this.commentForm.data.images.splice(index, 1)
    }

    canAppendImage() {
        return this.commentForm.get("images").length < MAX_IMAGES
    }

    canSubmitComment() {
        return this.commentForm.get("text").trim().length > 0
    }

    @loads(() => CommunityPostsAndCommentsStore.LoadingKeys["delete-post"])
    async deletePost(): Promise<boolean> {
        try {
            await CommunityAdminService.deleteV1AdminCommunityPost({
                postId: this.post.id,
            })

            Channel.send({
                name: "repository/updated",
                payload: { repository: "community-posts", action: "delete" },
            })

            return true
        } catch (e) {
            reportUnhandledApiError(e)
            return false
        }
    }

    @loads(() => CommunityPostsAndCommentsStore.LoadingKeys["delete-comment"])
    async deleteComment(id: number): Promise<boolean> {
        try {
            await CommunityAdminService.deleteV1AdminCommunityComment({
                commentId: id,
            })

            Channel.send({
                name: "repository/updated",
                payload: { repository: "community-comments", action: "delete" },
            })

            this.removeCommentFromLoaded(id)

            return true
        } catch (e) {
            reportUnhandledApiError(e)
            return false
        }
    }

    startEditingComment(id: number) {
        const comment = this.comments.find((comment) => comment.id === id)

        if (comment == null) {
            return
        }

        this.editCommentForm = new FormFields({
            id: comment.id,
            text: comment.text,
        })
    }

    cancelEditingComment() {
        this.editCommentForm = null
    }

    getEditingCommentForm(id: number) {
        if (this.editCommentForm == null) {
            return null
        } else if (this.editCommentForm.get("id") !== id) {
            return null
        }

        return this.editCommentForm
    }

    isEditingComment(id: number) {
        if (this.editCommentForm == null) {
            return false
        }
        return this.editCommentForm.get("id") === id
    }

    isEditingOtherComment(id: number) {
        if (this.editCommentForm == null) {
            return false
        }
        return this.editCommentForm.get("id") !== id
    }

    isPostLikedByUser(adminId?: number) {
        if (adminId == null) {
            return false
        }

        return this.post.likes.some(
            (author) =>
                author.objectType === "admin_user" &&
                author.objectId === adminId,
        )
    }

    isCommentLikedByUser(commentId: number, adminId?: number) {
        if (adminId == null) {
            return false
        }

        const comment = this.comments.find(
            (comment) => comment.id === commentId,
        )
        if (comment == null) {
            return false
        }

        return comment.likes.some(
            (author) =>
                author.objectType === "admin_user" &&
                author.objectId === adminId,
        )
    }

    @loads(() => CommunityPostsAndCommentsStore.LoadingKeys["like-comment"])
    async toggleCommentLike(commentId: number, adminId?: number) {
        if (adminId == null) {
            return
        }

        if (this.isCommentLikedByUser(commentId, adminId)) {
            try {
                this.removeLikeFromLoadedComment(commentId, adminId)
                await CommunityAdminService.deleteV1AdminCommunityCommentLike({
                    commentId,
                })
            } catch (e) {
                this.addLikeFromLoadedComment(commentId, adminId)
                reportUnhandledApiError(e)
            }
        } else {
            try {
                this.addLikeFromLoadedComment(commentId, adminId)
                await CommunityAdminService.postV1AdminCommunityCommentLike({
                    commentId,
                    request: {
                        author_name: this.getAuthorNameFromForm(),
                    },
                })
            } catch (e) {
                this.removeLikeFromLoadedComment(commentId, adminId)
                reportUnhandledApiError(e)
            }
        }
    }

    @loads(() => CommunityPostsAndCommentsStore.LoadingKeys["like-post"])
    async togglePostLike(adminId?: number) {
        if (adminId == null) {
            return
        }

        if (this.isPostLikedByUser(adminId)) {
            try {
                this.removeLikeFromLoadedPost(adminId)
                await CommunityAdminService.deleteV1AdminCommunityPostLike({
                    postId: this.post.id,
                })
            } catch (e) {
                this.addLikeFromLoadedPost(adminId)
                reportUnhandledApiError(e)
            }
        } else {
            try {
                this.addLikeFromLoadedPost(adminId)
                await CommunityAdminService.postV1AdminCommunityPostLike({
                    postId: this.post.id,
                    request: {
                        authorName: this.getAuthorNameFromForm(),
                    },
                })
            } catch (e) {
                this.removeLikeFromLoadedPost(adminId)
                reportUnhandledApiError(e)
            }
        }
    }

    @loads(() => CommunityPostsAndCommentsStore.LoadingKeys["block-unblock"])
    async blockUnblockTenant(action: "block" | "unblock") {
        try {
            switch (action) {
                case "block":
                    await CommunityAdminService.postV1AdminCommunityBlock({
                        communityId: this.post.communityId,
                        request: {
                            author_id: this.post.authorId,
                        },
                    })
                    reportSuccess(
                        t`view-community-post-modal-block-tenant-success`,
                    )
                    break
                case "unblock":
                    await CommunityAdminService.postV1AdminCommunityUnblock({
                        communityId: this.post.communityId,
                        request: {
                            author_id: this.post.authorId,
                        },
                    })
                    reportSuccess(
                        t`view-community-post-modal-unblock-tenant-success`,
                    )
                    break
            }

            return true
        } catch (error) {
            reportUnhandledApiError(error)
            return false
        }
    }

    @loads(() => CommunityPostsAndCommentsStore.LoadingKeys["update-comment"])
    async submitEditedComment() {
        if (this.editCommentForm == null) {
            return
        }

        const { id, text } = this.editCommentForm.data
        try {
            await CommunityAdminService.patchV1AdminCommunityComment({
                commentId: id,
                request: { text },
            })
            this.updateLoadedComment(id, { text })
            this.cancelEditingComment()

            Channel.send({
                name: "repository/updated",
                payload: { repository: "community-comments", action: "update" },
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    private async loadPost(id: number) {
        try {
            const post = await CommunityAdminService.getV2AdminCommunityPost({
                postId: id,
            })
            this.setPost(post)
            this.setComments(post.comments ?? [], id, post.access_type)
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    reloadPost() {
        return this.loadPost(this.post.id)
    }

    private updateLoadedComment(id: number, data: { text: string }) {
        const comment = this.comments.find((comment) => comment.id === id)
        if (comment == null) {
            return
        }

        Object.assign(comment, data)
    }

    private removeLikeFromLoadedComment(id: number, adminId: number) {
        const comment = this.comments.find((comment) => comment.id === id)
        if (comment == null) {
            return
        }

        comment.likes = comment.likes.filter(
            (like) =>
                like.objectType !== "admin_user" || like.objectId !== adminId,
        )
    }

    private addLikeFromLoadedComment(id: number, adminId: number) {
        const comment = this.comments.find((comment) => comment.id === id)
        if (comment == null) {
            return
        }

        comment.likes.push({
            objectType: "admin_user",
            objectId: adminId,
        })
    }

    private removeLikeFromLoadedPost(adminId: number) {
        this.post.likes = this.post.likes.filter(
            (like) =>
                like.objectType !== "admin_user" || like.objectId !== adminId,
        )
    }

    private addLikeFromLoadedPost(adminId: number) {
        this.post.likes.push({ objectType: "admin_user", objectId: adminId })
    }

    private setPost(post: community_PostWithAdminDetailsV2) {
        this._post = {
            id: post.post_id ?? -1,
            original_id: post.post_id ?? -1,
            itemType: IPostAndCommentsItemType.POST,
            authorId: post.author_id ?? -1,
            authorObjectType: (post.author?.object_type ??
                "none") as IPost["authorObjectType"],
            authorObjectId: post.author?.object_id ?? -1,
            authorName: getAuthorName(post),
            authorIsBlocked: post.author_is_blocked ?? false,
            categoryName: post.category_name ?? "",
            commentCount: post.comments_count ?? 0,
            likes: this.toLikeAuthors(post.likes ?? []),
            likeCount: post.likes?.length ?? 0,
            images: post.image_urls ?? [],
            createdDate: parseDate(post.created_date),
            text: post.text ?? "",
            accessType: post.access_type ?? "NONE",
            apartmentNumber: post.apartment_no ?? "-",
            internalObjectId: post.internal_apartment_id ?? "-",
            communityId: post.community_id ?? -1,
            flags: post.flags ?? {},
            is_handled:
                post.is_handled === undefined
                    ? null
                    : post.is_handled === true
                    ? true
                    : post.is_handled === false
                    ? false
                    : null,
            pollId: post.poll_id?.toString() ?? undefined,
        }
    }

    private setComments(
        comments: community_CommentOnPost[],
        postId: number,
        accessType?: avy_api_pkg_admin_domain_ResourceAccessType,
    ) {
        const nextComments = comments.map(
            (comment): IComment => ({
                id: comment.comment_id ?? -1,
                postId,
                original_id: comment.comment_id ?? -1,
                itemType: IPostAndCommentsItemType.COMMENT,
                authorObjectType: (comment.author?.object_type ??
                    "none") as IPost["authorObjectType"],
                authorObjectId: comment.author?.object_id ?? -1,
                authorName: getAuthorName(comment),
                createdDate: parseDate(comment.created_at),
                likes: this.toLikeAuthors(comment.likes ?? []),
                text: comment.text ?? "",
                accessType: accessType ?? "NONE",
                images: comment.image_urls ?? [],
                apartmentNumber: comment.apartment_no ?? "-",
                internalObjectId: comment.internal_apartment_id ?? "-",
                flags: comment.flags ?? {},
                is_handled:
                    comment.is_handled === undefined
                        ? null
                        : comment.is_handled === true
                        ? true
                        : comment.is_handled === false
                        ? false
                        : null,
            }),
        )

        nextComments.sort((a, b) => {
            const aTime = a.createdDate?.getTime() ?? 0
            const bTime = b.createdDate?.getTime() ?? 0
            return aTime - bTime
        })

        this.comments = nextComments
    }

    private toLikeAuthors(authors: community_Author[]): ILikeAuthor[] {
        return authors.map((author) => ({
            authorName: author.author_name ?? "",
            objectId: author.object_id ?? -1,
            objectType: (author.object_type ??
                "none") as ILikeAuthor["objectType"],
        }))
    }

    private removeCommentFromLoaded(id: number) {
        this.comments = this.comments.filter((comment) => comment.id !== id)
    }

    private resetCommentForm() {
        this.commentForm.set("images", [])
        this.commentForm.set("text", "")
    }

    public getAuthorNameFromForm() {
        const id = this.commentForm.get("authorId")
        const author = this.authors.find((author) => author.id === id)
        return author?.name
    }

    private setInitialAuthor() {
        //get local stored author
        const localAuthorId = this.getAuthorFromLocalStore()
        //validate if the stored author is pressent in the author list
        const authorExist = this.authors.find(
            (author) => author.id === localAuthorId,
        )

        if (authorExist !== undefined && localAuthorId != null) {
            this.commentForm.set("authorId", localAuthorId)
            return
        }

        if (authorExist === undefined || this.authors.length > 0) {
            this.setAuthorIdToLocalStore(this.authors[0].id)
            this.commentForm.set("authorId", this.authors[0].id)
            return
        }
    }

    private setAuthors(authors: IAuthor[]) {
        this.authors = authors
    }

    private setInitialized() {
        this.initialized = true
    }

    private validate() {
        if (this.commentForm.get("text").trim().length === 0) {
            this.commentForm.setError("text", t`errors.required`)
        }
    }

    private listenToRepositoryChanges() {
        this.repositoryChangeListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    (event.payload.repository === "community-posts" ||
                        event.payload.repository ===
                            "community-posts-and-comments") &&
                    event.payload.action === "update"
                ) {
                    await this.reloadPost()
                }
            },
        )
    }

    private getAuthorFromLocalStore() {
        return window.localStorage.getItem("authorId")
    }

    public setAuthorIdToLocalStore(authorId: string) {
        window.localStorage.setItem("authorId", authorId)
    }

    setNavigationData(postIds: number[] | undefined, currentId: number) {
        this.allPostIds = postIds ?? []
        this.currentPostId = currentId
        this.currentIndex = this.allPostIds.findIndex((id) => id === currentId)
    }

    goNext(): boolean {
        return this.currentIndex < this.allPostIds.length - 1
    }

    goPrevious(): boolean {
        return this.currentIndex > 0
    }

    async navigateToPost(direction: "next" | "previous") {
        if (direction === "next" && !this.goNext()) return
        if (direction === "previous" && !this.goPrevious()) return

        const newIndex =
            direction === "next" ? this.currentIndex + 1 : this.currentIndex - 1
        const newPostId = this.allPostIds[newIndex]

        if (newPostId > 0) {
            this.currentIndex = newIndex
            this.currentPostId = newPostId
            await this.init(newPostId, this.authors)
        }
    }
}
