import { Box, styled } from "@mui/material"
import { observer } from "mobx-react"

import { PollActionControl } from "src/components/PollActionControl"
import { PostBody } from "src/modals/community-posts-and-comments/components/Post/Body"
import { CommentList } from "src/modals/community-posts-and-comments/components/Post/CommentList"
import { PostFooter } from "src/modals/community-posts-and-comments/components/Post/Footer"
import { CommunityPostsAndCommentsStore } from "src/modals/community-posts-and-comments/store"
import { useStore } from "src/store/lib/useStore"
import { EModalMode } from "src/types/modal/modal"

const Wrap = styled("div")({ flex: 1, overflow: "auto", padding: "16px" })

const Hr = styled("hr")(({ theme }) => ({
    margin: "16px 0",
    border: "none",
    height: "1px",
    background: theme.palette.grey[500],
}))

export const Post = observer(() => {
    const store = useStore(CommunityPostsAndCommentsStore)
    return (
        <Wrap>
            <PostBody />
            {Boolean(store.post.pollId) && (
                <Box mt={2}>
                    <PollActionControl
                        pollId={store.post.pollId}
                        mode={EModalMode.View}
                    />
                </Box>
            )}
            <PostFooter />
            <Hr />
            <CommentList />
        </Wrap>
    )
})
