// Common types used across multiple components
import { poll_PollForAdmin } from "src/api"
import { FieldTypeDropdownKeys } from "src/components/Inputs/types/IFieldTypeDropdown"

export interface IPollModalProps {
    id?: string
    sourceContext?: PollSourceContext
    isDuplicate?: boolean
    postId?: number
}

export type PollTypeKeys =
    | FieldTypeDropdownKeys.RadioButton
    | FieldTypeDropdownKeys.Checkbox

export enum PollSourceContext {
    PollList = "poll-list",
    Embedded = "embedded",
}

// Common props interface for sections that need poll data
export interface IPollSectionProps {
    poll: poll_PollForAdmin
}

export interface IPollFormFields {
    // Custom fields for our form
    question: string
    name: string
    options: string[]
    endDate: Date | null
    questionType: PollTypeKeys
    accessGroupId?: number
    segmentIds?: number[]
}
