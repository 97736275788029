import { t } from "@lingui/macro"
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Box,
} from "@mui/material"
import { observer } from "mobx-react-lite"
import { useCallback } from "react"

import { Avatar } from "src/components/Avatar"
import { PollActionControl } from "src/components/PollActionControl"

import { useUniqueId } from "src/lib/unique-id"
import { CommunityPostsAndCommentsEditStore } from "src/modals/community-posts-and-comments-edit/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { EModalMode } from "src/types/modal/modal"

export const MessageSection = observer(() => {
    const gstore = useStore(GlobalStore)
    const store = useStore(CommunityPostsAndCommentsEditStore)
    const authorSelectId = useUniqueId()

    const handleAuthorChange = useCallback(
        (event: SelectChangeEvent<string | null>) => {
            store.form.set("authorId", event.target.value)
            event.target.value != null &&
                store.setAuthorIdToLocalStore(event.target.value)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [store.form],
    )

    const handlePollIdChange = useCallback(
        (pollId: string | null) => {
            store.form.set("pollId", pollId)
        },

        [store.form],
    )

    return (
        <>
            {gstore.session.hasAccessToModule("community_author_name") && (
                <FormControl fullWidth>
                    <InputLabel id={authorSelectId}>
                        {t`community-post-detail-modal.message-section.author-label`}
                    </InputLabel>
                    <Select
                        labelId={authorSelectId}
                        label={t`community-post-detail-modal.message-section.author-label`}
                        value={store.form.get("authorId")}
                        onChange={handleAuthorChange}
                        disabled={
                            store.isEditing ||
                            store.form.get("accessType") === "READ"
                        }
                    >
                        {store.authors.map((author) => (
                            <MenuItem key={author.id} value={author.id}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Avatar size={24} name={author.name} />
                                    <span>{author.name}</span>
                                </Stack>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <TextField
                label={t`community-post-detail-modal.message-section.message-label`}
                rows={5}
                onChange={store.form.setter("text")}
                value={store.form.get("text")}
                error={Boolean(store.form.error("text"))}
                helperText={store.form.error("text")}
                disabled={store.form.get("accessType") === "READ"}
                multiline
            />
            <Box mt={2}>
                <PollActionControl
                    pollId={store.form.get("pollId")}
                    onPollIdChange={handlePollIdChange}
                    mode={EModalMode.Edit}
                />
            </Box>
        </>
    )
})
