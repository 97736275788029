import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useMemo } from "react"

import { IStatusChipProps, StatusChip } from "src/components/StatusChip"
import { IPollStatusChipProps } from "src/modals/polls/components/PollStatusChip/types"

export const PollStatusChip = observer(({ status }: IPollStatusChipProps) => {
    const chipProps = useMemo((): IStatusChipProps => {
        switch (status) {
            case "open":
                return {
                    state: "positive",
                    label: t`polls.status-open`,
                }
            case "closed":
                return {
                    state: "negative",
                    label: t`polls.status-closed`,
                }
            case "scheduled":
                return {
                    state: "info",
                    label: t`polls.status-scheduled`,
                }
            case "draft":
                return {
                    state: "neutral",
                    label: t`polls.status-draft`,
                }
            case "deleted":
                return {
                    state: "dark",
                    label: t`polls.status-deleted`,
                }
            default:
                return {
                    state: "other",
                    label: status,
                }
        }
    }, [status])

    return <StatusChip {...chipProps} />
})
