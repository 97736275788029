import { Box, TextField, useTheme } from "@mui/material"
import { useMemo } from "react"

import { TooltipWithIcon } from "src/components/Tooltips/TooltipWithIcon"
import { IModalTextField } from "src/types/modal-text-field"

export const ModalTextField = (props: IModalTextField) => {
    const theme = useTheme()
    const backgroundColor =
        props.disabled === true
            ? theme.palette.grey[300]
            : theme.palette.grey[100]
    const value = props.type === "number" ? props.value ?? 0 : props.value ?? ""

    const multiline = useMemo(
        () =>
            (props.rows !== undefined && props.rows > 1) ||
            (props.maxRows !== undefined && props.maxRows > 1),
        [props.rows, props.maxRows],
    )

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange !== undefined) {
            const value = event.target.value
            const trimmedValue =
                props.type === "number" ? parseInt(value) : value.trimStart()

            props.onChange({
                ...event,
                target: { ...event.target, value: trimmedValue },
                currentTarget: { ...event.currentTarget, value: trimmedValue },
            } as React.ChangeEvent<HTMLInputElement>)
        }
    }

    return (
        <Box
            sx={{ display: "flex", gap: "8px" }}
            style={{
                breakInside: "avoid",
                alignItems: "center",
            }}
        >
            <TextField
                type={props.type ?? "text"}
                label={props.label}
                placeholder={props.placeholder}
                onChange={handleChange}
                value={value}
                helperText={props.helperText}
                error={props.error}
                disabled={props.disabled}
                rows={props.rows}
                multiline={multiline}
                maxRows={props.maxRows}
                style={{ backgroundColor }}
                inputProps={{
                    maxLength: props.maxLength,
                    ...(props.type === "number"
                        ? { min: 0, onWheel: (e) => e.currentTarget.blur() } // disables scroll property for number input
                        : {}),
                }}
                size={props.size}
                data-testid="modal-form-text-field"
            />
            {props.variant === "withToolTip" && props.tooltipText && (
                <div>
                    <TooltipWithIcon
                        tooltipText={props.tooltipText}
                        defaultValue={props.defaultValue}
                    />
                </div>
            )}
        </Box>
    )
}
