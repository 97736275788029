import { makeAutoObservable } from "mobx"
import React from "react"
import { t } from "@lingui/macro"

import { PollAdminService, poll_PollForAdmin } from "src/api"
import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"
import { Channel } from "src/channel"
import { reportError } from "src/lib/report"

export class PollActionControlStore {
    static Context = React.createContext<PollActionControlStore | null>(null)
    static LoadingKeys = createLoadingKeys("fetch", "delete")

    private repositoryChangeListenerDisposer?: () => void
    private _pollDetails: poll_PollForAdmin | null = null
    private _currentPollId: string | null = null

    constructor() {
        makeAutoObservable(this)
    }

    get pollDetails() {
        return this._pollDetails
    }

    get currentPollId() {
        return this._currentPollId
    }

    private setCurrentPollId(pollId: string | null) {
        this._currentPollId = pollId
    }

    private setPollDetails(poll: poll_PollForAdmin | null) {
        this._pollDetails = poll
    }

    public async initialize(pollId?: string | null) {
        if (pollId != null) {
            this.setCurrentPollId(pollId)
            await this.fetchPollDetails(pollId)
        }
        this.listenToRepositoryChanges()
    }

    @loads(() => PollActionControlStore.LoadingKeys.fetch)
    public async fetchPollDetails(pollId: string) {
        try {
            const poll = await PollAdminService.getV1AdminPoll({
                pollId: parseInt(pollId),
            })
            this.setPollDetails(poll)
        } catch (error) {
            this.setPollDetails(null)
            reportError(t`poll-action-control.error.fetch-failed`, error)
        }
    }

    @loads(() => PollActionControlStore.LoadingKeys.delete)
    private async delete() {
        if (this.currentPollId == null) return

        try {
            await PollAdminService.deleteV1AdminPoll({
                pollId: parseInt(this.currentPollId),
            })
        } catch (error) {
            reportError(t`poll-action-control.error.delete-failed`, error)
        }
    }

    public async deletePoll() {
        await this.delete()
        this.setCurrentPollId(null)
        this.setPollDetails(null)
    }

    private listenToRepositoryChanges() {
        this.repositoryChangeListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "poll"
                ) {
                    if (
                        event.payload.item?.id != null &&
                        event.payload.action === "create"
                    ) {
                        const pollId = event.payload.item?.id.toString()
                        this.setCurrentPollId(pollId)
                        // fetch poll details will be called with handlePollIdChange
                    } else if (
                        this.currentPollId != null &&
                        event.payload.action === "update"
                    ) {
                        await this.fetchPollDetails(this.currentPollId)
                    }
                }
            },
        )
    }

    dispose() {
        this.repositoryChangeListenerDisposer?.()
    }
}
