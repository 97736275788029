import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"
import { DataGridProProps, GridFilterModel } from "@mui/x-data-grid-pro"

import { ListPage } from "src/components/ListPage"
import { CommunityPostsAndCommentsEditModal } from "src/modals/community-posts-and-comments-edit"
import { ConfirmModal } from "src/modals/confirm"
import { CommunityPostsAndCommentsModal } from "src/modals/community-posts-and-comments"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { FilterModel, SortModel } from "src/types/data-grid-pro"
import { Repository } from "src/types/channel"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { IPostAndCommentsItem } from "src/types/community-posts-and-comments/communityPostsAndComments"
import { CommunityPostsAndCommentsStore } from "src/views/community-posts-and-comments/store"
import useCommunityPostsColumns from "src/views/community-posts-and-comments/hooks/useCommunityPostsColumns"
import { CustomGridTreeDataGroupingCell } from "src/views/community-posts-and-comments/components/CustomGridTreeDataGroupingCell"
import MultiselectFlagsFilter from "src/views/community-posts-and-comments/components/MultiselectFlagsFilter"
import { IPageFilterProps } from "src/components/PageFilter"
import { IPageHeaderProps } from "src/components/PageHeader"
import useRouteParams from "src/views/community-posts-and-comments/hooks/useRouteParams"
import { useHandleAndReportActions } from "src/shared/community-posts-and-comments/hooks/useHandleAndReportActions"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { TrackingSource } from "src/analytics/constants/sources"
import { isPostItem } from "src/views/community-overview/helpers/communityHelpers"

const REPOSITORY: Repository = "community-posts-and-comments"

const View = observer(() => {
    const store = useStore(CommunityPostsAndCommentsStore)
    const globalStore = useStore(GlobalStore)
    const columns = useCommunityPostsColumns()
    const allParams = useRouteParams()
    const { openModal } = useHandleAndReportActions()

    const advanceQuery = useMemo(
        () => ({
            sort: getSortModelForRepository(
                REPOSITORY,
                globalStore.session.dataGridSortModel,
            ),
            filter: getFilterModelForRepository(
                REPOSITORY,
                globalStore.session.dataGridFilterModel,
            ),
        }),
        [
            globalStore.session.dataGridSortModel,
            globalStore.session.dataGridFilterModel,
        ],
    )

    useEffect(() => {
        ;(async () => {
            await store.init(globalStore.session.accessGroupId, advanceQuery)
        })()
        return () => {
            store.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalStore.session.accessGroupId, store.selectedFlagFilter])

    const getDataGridInitialFilteringState = useCallback(():
        | GridFilterModel
        | undefined => {
        if (allParams.length === 0) return undefined

        const params = store.handleParams(columns, allParams)
        if (params?.length === 0) return undefined

        return { items: params }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, allParams])

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const finalParams: Record<string, string> = {}

            // eslint-disable-next-line @typescript-eslint/naming-convention
            const is_handled = allParams
                .filter((param) => param.param === "is_handled")
                .map((param) => param.value)

            if (is_handled.length > 0) {
                const lastValue = is_handled[is_handled.length - 1]
                finalParams["is_handled"] = String(lastValue)
            }

            const flag = allParams.find(
                (param) => param.param === "flag",
            )?.value
            if (flag !== undefined) {
                finalParams["flag"] = String(flag)
            }

            model.items.forEach((item) => {
                if (item.value === undefined && item.field === "is_handled") {
                    delete finalParams[item.field]
                } else {
                    if (item.value !== undefined) {
                        finalParams[item.field] = String(item.value)
                    }
                }
            })

            return await store.query({
                ...advanceQuery,
                filter: {
                    ...model,
                    items: model.items.map((item) => ({
                        ...item,
                        value:
                            item.value === undefined
                                ? undefined
                                : item.value === null
                                ? null
                                : item.value,
                    })),
                },
            })
        },
        [allParams, store, advanceQuery],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) =>
            await store.query({ ...advanceQuery, sort: model }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [advanceQuery],
    )

    const handleDeleteConfirm = useCallback(
        (item: IPostAndCommentsItem) => async (confirmed: boolean) => {
            if (confirmed) {
                const isPost = isPostItem(item)
                if (isPost) {
                    trackModuleEvent("Delete post", {
                        [MixpanelProperties.DeletedFrom]:
                            TrackingSource.ListView,
                        [MixpanelProperties.EventDefinition]:
                            'When user confirms the delete with "ok"',
                        [MixpanelProperties.PostID]: item.original_id,
                        [MixpanelProperties.PostText]: item.text,
                        [MixpanelProperties.DeletedBy]:
                            globalStore.session.user?.name,
                    })
                } else {
                    trackModuleEvent("Delete comment", {
                        [MixpanelProperties.DeletedFrom]:
                            TrackingSource.ListView,
                        [MixpanelProperties.EventDefinition]:
                            'When user confirms the delete with "ok"',
                        [MixpanelProperties.CommentID]: item.original_id,
                        [MixpanelProperties.PostID]: item.post_id,
                        [MixpanelProperties.CommentText]: item.text,
                        [MixpanelProperties.DeletedBy]:
                            globalStore.session.user?.name,
                    })
                }

                isPost
                    ? await store.deletePost(item?.original_id)
                    : await store.deleteComment(item?.original_id)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const openDeleteConfirmModal = useCallback(
        (item: IPostAndCommentsItem) => () => {
            const isPost = isPostItem(item)
            openModal(ConfirmModal, {
                onConfirm: handleDeleteConfirm(item),
                title: isPost
                    ? t`community-posts-view.delete-post-confirm-modal.title`
                    : t`community-posts-comments.delete-comment`,
                content: isPost
                    ? t`community-posts-view.delete-post-confirm-modal.content`
                    : t`community-posts-comments.are-delete-comment?`,
            })
        },
        [openModal, handleDeleteConfirm],
    )

    const openDetailModalHandler = useCallback(
        (item) => {
            const isPost = isPostItem(item.row)

            // Track the event when a user opens a post or comment
            trackModuleEvent("Open community post", {
                [MixpanelProperties.EntryPoint]: isPost
                    ? TrackingSource.Post
                    : TrackingSource.Comment,
                [MixpanelProperties.Flags]: getFlagsFromItem(item.row),
            })

            globalStore.modals.open(
                () => (
                    <CommunityPostsAndCommentsModal
                        id={
                            isPost
                                ? item.row["original_id"]
                                : item.row["parent_id"]
                        }
                        allPostIds={store.allPostIds}
                    />
                ),
                {
                    hasPreview: true,
                },
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalStore.modals, store.allPostIds],
    )

    const handleEditItem = useCallback(
        (item: IPostAndCommentsItem) => () => {
            const isPost = isPostItem(item)
            if (isPost) {
                globalStore.modals.open(
                    () => (
                        <CommunityPostsAndCommentsEditModal
                            id={item.original_id}
                        />
                    ),
                    { hasPreview: true },
                )
            } else {
                // For comments, open the post view with the comment in focus
                globalStore.modals.open(
                    () => (
                        <CommunityPostsAndCommentsModal
                            id={item.parent_id ?? 0}
                            allPostIds={store.allPostIds}
                            focusCommentId={item.original_id}
                        />
                    ),
                    { hasPreview: true },
                )
            }
        },
        [globalStore.modals, store.allPostIds],
    )

    const handleDuplicateItem = useCallback(
        (item: IPostAndCommentsItem) => async () => {
            if (!isPostItem(item)) return // Only allow duplicating posts

            try {
                // First duplicate the post (and its poll if it exists)
                const result = await store.duplicatePost(item.original_id)

                if (result != null) {
                    const { originalPost, newPollId } = result

                    // Open the edit modal with duplicate flag
                    globalStore.modals.open(
                        () => (
                            <CommunityPostsAndCommentsEditModal
                                originalPost={originalPost}
                                newPollId={newPollId}
                                isDuplicate={true}
                            />
                        ),
                        { hasPreview: true },
                    )
                }
            } catch (error) {
                reportError(t`community-posts-view.duplicate-post-error`)
            }
        },
        [globalStore.modals, store],
    )

    // Helper function to extract flags from a post/comment item
    const getFlagsFromItem = (item: IPostAndCommentsItem): string => {
        const flags = []

        if (item.flags?.security_risk ?? false) flags.push("Safety risk")
        if (item.flags?.community_guidelines_violation ?? false)
            flags.push("Guideline violation")
        if (item.flags?.maintenance ?? false) flags.push("Maintenance")
        if (item.flags?.customer_support ?? false)
            flags.push("Customer support")
        if (item.flags?.reported_by_tenant ?? false)
            flags.push("Reported by tenant")
        if (item.flags?.reported_by_admin ?? false)
            flags.push("Reported by admin")

        return flags.join(", ")
    }

    const rowActionsRenderer = useCallback(
        (item: IPostAndCommentsItem) => {
            const isPost = isPostItem(item)
            return [
                {
                    text: isPost
                        ? t`community-posts-view.edit-post-button`
                        : t`community-posts-comments.edit-comment`,
                    onClick: handleEditItem(item),
                    hidden: !store.canEditItem(item),
                },
                {
                    text: t`community-posts-view.duplicate-post-button`,
                    onClick: handleDuplicateItem(item),
                    hidden: !isPost, // Only show for posts, not comments
                },
                {
                    text: isPost
                        ? t`community-posts-view.delete-post-button`
                        : t`community-posts-comments.delete-comment`,
                    destructive: true,
                    onClick: openDeleteConfirmModal(item),
                },
            ]
        },
        [handleEditItem, handleDuplicateItem, openDeleteConfirmModal, store],
    )

    const filter: IPageFilterProps = useMemo(
        () => ({
            actions: <MultiselectFlagsFilter />,
        }),
        [],
    )

    const groupingColDef: DataGridProProps["groupingColDef"] = {
        headerName: "",
        renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
        minWidth: 40,
        maxWidth: 40,
        disableReorder: true,
        hideable: false,
    }
    const header: IPageHeaderProps = useMemo(
        () => ({
            header: t`community-posts-view.header`,
            breadcrumbs: [
                t`community-posts-view.community-breadcrumb`,
                t`community-posts-comments.posts-breadcrumb`,
            ],
            createOptions: {
                onClick() {
                    globalStore.modals.open(
                        () => <CommunityPostsAndCommentsEditModal />,
                        {
                            hasPreview: true,
                        },
                    )
                },
                item: t`community-posts-view.create-community-post`,
            },
        }),
        [globalStore.modals],
    )
    const loading =
        globalStore.loading.is(
            CommunityPostsAndCommentsStore.LoadingKeys.init,
        ) || !store.posts.meta.initialized

    return (
        <ListPage header={header} loading={loading} filter={filter}>
            <DataGridProTable
                paginator={store.posts}
                data={store.posts.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    filtering: "server",
                    sorting: "server",
                }}
                onRowClickEvent={openDetailModalHandler}
                repository={REPOSITORY}
                rowActionsRenderer={rowActionsRenderer}
                loading={globalStore.loading.is(
                    CommunityPostsAndCommentsStore.LoadingKeys.loading,
                )}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
                treeData={true}
                groupingColDef={groupingColDef}
                filteringInitialState={getDataGridInitialFilteringState()}
                rowHeight={60}
            />
        </ListPage>
    )
})

export const CommunityPostsAndCommentsView = () => (
    <StoreProvider Store={CommunityPostsAndCommentsStore}>
        <View />
    </StoreProvider>
)
