import { t } from "@lingui/macro"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { useCallback, useMemo } from "react"

import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { SegmentIdsCell } from "src/components/TableCells/SegmentIdsCell"
import { Time } from "src/components/Time"
import { IColumn } from "src/types/data-grid-pro"
import { IPollsItem } from "src/views/polls/types/polls-item"
import { PollStatusChip } from "src/modals/polls/components/PollStatusChip"
import { poll_PollStatus } from "src/api"

export const useGetPollColumns = (): IColumn<IPollsItem>[] => {
    const renderSegmentIdsCell = useCallback(
        (params: GridRenderCellParams) => (
            <SegmentIdsCell segmentNames={params.value} />
        ),
        [],
    )

    const renderAccessTypeCell = useCallback(
        (params: GridRenderCellParams) => (
            <AccessTypeHeader accessType={params.value} />
        ),
        [],
    )

    const renderDateCell = useCallback((params: GridRenderCellParams) => {
        return <Time date={params.value} />
    }, [])

    const renderStatusCell = useCallback((params: GridRenderCellParams) => {
        return <PollStatusChip status={params.value as poll_PollStatus} />
    }, [])

    return useMemo(
        () => [
            {
                field: "poll_id",
                headerName: t`polls.id`,
                filterable: false,
                sortable: false,
                width: 100,
            },
            {
                field: "question",
                headerName: t`polls.question`,
                flex: 2,
            },
            {
                field: "close_at",
                headerName: t`polls.end-date`,
                filterable: false,
                flex: 1,
                renderCell: renderDateCell,
                type: "date",
            },
            {
                field: "segment_names",
                headerName: t`polls.visible-for`,
                filterable: false,
                sortable: false,
                flex: 1,
                renderCell: renderSegmentIdsCell,
            },
            {
                field: "access_type",
                headerName: t`polls.access`,
                filterable: false,
                sortable: false,
                flex: 1,
                renderCell: renderAccessTypeCell,
            },
            {
                field: "votes_count",
                headerName: t`polls.votes`,
                filterable: false,
                sortable: true,
                width: 100,
            },
            {
                field: "status",
                headerName: t`polls.status`,
                filterable: true,
                sortable: true,
                width: 120,
                renderCell: renderStatusCell,
            },
        ],
        [
            renderAccessTypeCell,
            renderSegmentIdsCell,
            renderDateCell,
            renderStatusCell,
        ],
    )
}
