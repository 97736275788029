import { styled } from "@mui/material/styles"
import { LinearProgress as MuiLinearProgress, Stack } from "@mui/material"

export const StyledPollProgressBar = styled(MuiLinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 1,
    backgroundColor: theme.palette.utility.blueFaded.main,
    "& .MuiLinearProgress-bar": {
        backgroundColor: theme.palette.utility.blue.dark,
    },
}))

export const StyledPollOptionHeader = styled(Stack)({
    marginBottom: 0.5,
    flexDirection: "row",
    justifyContent: "space-between",
})
