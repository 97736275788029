import { t } from "@lingui/macro"
import { Box, Divider, Stack, Typography } from "@mui/material"
import { observer } from "mobx-react"

import { StyledPollSummaryContainer } from "./styled"

import { IPollSectionProps } from "src/modals/polls/types/index"

export const SummarySection = observer(({ poll }: IPollSectionProps) => {
    const accessGroup = poll.access_group_id ?? "-"
    const viewsCount = poll.views_count ?? 0
    const formattedCloseDate =
        poll.close_at !== undefined
            ? new Date(poll.close_at).toLocaleDateString()
            : "-"

    return (
        <Stack>
            <Divider />
            <StyledPollSummaryContainer>
                <Box>
                    <Typography variant="subtitle2" marginBottom={0.5}>
                        {t`poll.target-group`}
                    </Typography>
                    <Typography variant="body2">{accessGroup}</Typography>
                </Box>
                <Box>
                    <Typography variant="subtitle2" marginBottom={0.5}>
                        {t`poll.views`}
                    </Typography>
                    <Typography variant="body2">{viewsCount}</Typography>
                </Box>
                <Box>
                    <Typography variant="subtitle2" marginBottom={0.5}>
                        {t`poll.closes-at`}
                    </Typography>
                    <Typography variant="body2">
                        {formattedCloseDate}
                    </Typography>
                </Box>
            </StyledPollSummaryContainer>
        </Stack>
    )
})
