import {
    Alert,
    Box,
    Button,
    Stack,
    useMediaQuery,
    useTheme,
    IconButton,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"
import { t } from "@lingui/macro"
import {
    Check,
    ArrowBackOutlined,
    ArrowForwardOutlined,
} from "@mui/icons-material"

import { AuthorSelect } from "./components/Author"

import { PostWrapper, HeaderWrapper, AuthorWrapper } from "./styled"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { ModalHeader } from "src/components/ModalHeader"
import { CommentForm } from "src/modals/community-posts-and-comments/sections/CommentFormSection"
import { PostHeaderSection } from "src/modals/community-posts-and-comments/sections/PostHeaderSection"
import { Post } from "src/modals/community-posts-and-comments/sections/PostSection"

import { CommunityPostsAndCommentsStore } from "src/modals/community-posts-and-comments/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { handledPost } from "src/shared/community-posts-and-comments/helpers/postAndCommentsActions"
import { useGetAuthors } from "src/shared/community-posts-and-comments/hooks/useGetAuthors"
import { ICommunityPostsAndCommentsModalProps } from "src/modals/community-posts-and-comments/types"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

const View = observer((props: ICommunityPostsAndCommentsModalProps) => {
    const { id, allPostIds } = props
    const store = useStore(CommunityPostsAndCommentsStore)
    const gstore = useStore(GlobalStore)
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const { authors, hasAccessToAuthorNameModule } = useGetAuthors()

    useEffect(() => {
        ;(async () => {
            store.setNavigationData(allPostIds, id)
            await store.init(id, authors)
        })()
    }, [store, id, authors, allPostIds])

    const handleActions = useCallback(async (action: "previous" | "next") => {
        // Track browsing community posts
        trackModuleEvent("Browse community posts", {
            [MixpanelProperties.EventDefinition]:
                "When clicking next or previous post",
            [MixpanelProperties.PostID]: store.post.id,
            [MixpanelProperties.PostText]: store.post.text,
            [MixpanelProperties.BrowsedBy]: gstore.session.user?.name,
            [MixpanelProperties.PostAuthor]: store.post.authorName,
            [MixpanelProperties.Action]: action,
        })

        await store.navigateToPost(action)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleMarkAsHandled = async () => {
        trackModuleEvent("Mark post as handled", {
            [MixpanelProperties.HandledFrom]: "post",
            [MixpanelProperties.EventDefinition]: "User marking as handled",
            [MixpanelProperties.PostID]: store.post.id,
            [MixpanelProperties.PostText]: store.post.text,
            [MixpanelProperties.HandledBy]: gstore.session.user?.name,
        })

        if (Boolean(store.post.is_handled)) return

        await handledPost(store.post.id, false)
        await store.reloadPost()
    }

    const renderNavigation = useMemo(() => {
        if (allPostIds == null || allPostIds.length <= 1) return null

        return (
            <Stack
                direction="row"
                spacing={1}
                justifyContent={"space-between"}
                sx={{ pt: 1 }}
            >
                <IconButton
                    aria-label="Preview"
                    disabled={!Boolean(store.goPrevious())}
                    title={t`chatbot-view.modal.preview-conversation`}
                    onClick={() => handleActions("previous")}
                >
                    <ArrowBackOutlined />
                </IconButton>
                <IconButton
                    aria-label="Next"
                    disabled={!Boolean(store.goNext())}
                    title={t`chatbot-view.modal.next-conversation`}
                    onClick={() => handleActions("next")}
                >
                    <ArrowForwardOutlined />
                </IconButton>
            </Stack>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPostIds, handleActions, store.currentIndex, store.currentPostId])

    if (
        gstore.loading.is(CommunityPostsAndCommentsStore.LoadingKeys["init"]) ||
        !store.initialized ||
        gstore.loading.is(
            CommunityPostsAndCommentsStore.LoadingKeys["block-unblock"],
        ) ||
        gstore.loading.is(CommunityPostsAndCommentsStore.LoadingKeys["reload"])
    ) {
        return <CenteredSpinner />
    }

    const renderHeader = (
        <HeaderWrapper smallScreen={smallScreen}>
            <ModalHeader />
            <Stack direction="row" spacing={1} paddingBottom={1}>
                <Button
                    endIcon={
                        store.post.is_handled !== null ? (
                            <Check fontSize="small" />
                        ) : undefined
                    }
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={handleMarkAsHandled}
                >
                    {store.post.is_handled !== null
                        ? t`view-community-post-modal.handled`
                        : t`view-community-post-modal.mark-as-handled`}
                </Button>
                {hasAccessToAuthorNameModule && (
                    <AuthorWrapper smallScreen={smallScreen}>
                        <AuthorSelect />
                    </AuthorWrapper>
                )}
            </Stack>
        </HeaderWrapper>
    )

    return (
        <Box display="flex" flexDirection="column" height="100%">
            {renderHeader}
            {renderNavigation}
            {Boolean(store.commentForm.error("generic")) && (
                <Alert severity="error">
                    {store.commentForm.error("generic")}
                </Alert>
            )}
            <PostWrapper>
                <PostHeaderSection />
                <Post />
                <CommentForm />
            </PostWrapper>
        </Box>
    )
})

export const CommunityPostsAndCommentsModal = observer(
    (props: ICommunityPostsAndCommentsModalProps) => (
        <StoreProvider Store={CommunityPostsAndCommentsStore}>
            <View {...props} />
        </StoreProvider>
    ),
)
