import { t } from "@lingui/macro"

import { fieldsVerificationDataSet } from "src/modals/contact-forms-form-builder/constants/fieldsVerificationDataSet"
import { IFieldsVerificationDataSet } from "src/modals/contact-forms-form-builder/types/fieldsVerficationDataset"
import { FieldTypeDropdownKeys } from "src/components/Inputs/types/IFieldTypeDropdown"

import {
    IDynamicFieldsListItemProps,
    IExternalDynamicFieldsListItemProps,
} from "src/modals/contact-forms-form-builder/types/fieldsListItem"

import { OutputType } from "src/types/output"

export const dynamicFieldsValidationCheck = (
    dynamicFieldsList: IDynamicFieldsListItemProps[],
    externalDynamicFieldsList: IExternalDynamicFieldsListItemProps[],
    outputTypeSelectedValue: string,
    setHasError: (value: boolean) => void,
) => {
    if (outputTypeSelectedValue !== OutputType.Integration) {
        const list = [...dynamicFieldsList]
        list.map((dynamicField) => {
            Object.keys(fieldsVerificationDataSet).forEach((key: string) => {
                const fieldKey = key as keyof IFieldsVerificationDataSet
                const field = fieldsVerificationDataSet[fieldKey]
                const dynamicFieldItem = dynamicField[fieldKey]
                const dynamicFieldItemIsArray =
                    dynamicFieldItem instanceof Array
                const isFieldToBeChecked =
                    field.verificationType.length > 0
                        ? field.verificationType.includes(
                              dynamicField.dropdownValue
                                  .value as FieldTypeDropdownKeys,
                          )
                        : true

                if (isFieldToBeChecked) {
                    if (dynamicFieldItemIsArray) {
                        dynamicFieldItem.map((item) => {
                            if (item.value === "") {
                                item.errorMessage = t`errors.required`
                                setHasError(true)
                            }

                            return item
                        })
                    } else if (dynamicFieldItem.value === "") {
                        dynamicFieldItem.errorMessage = t`errors.required`
                        setHasError(true)
                    }
                }
            })

            return dynamicField
        })
        return list
    } else {
        const list = [...externalDynamicFieldsList]
        list.map((item) => {
            if (item.externalFieldTypeDropdownValue.value === "") {
                item.externalFieldTypeDropdownValue.errorMessage = t`errors.required`
                setHasError(true)
            }
            if (item.question.value === "") {
                item.question.errorMessage = t`errors.required`
                setHasError(true)
            }
            return item
        })
        return list
    }
}
