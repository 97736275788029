import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"

import { TemplateFeatureStore } from "./store"

import { ListPage } from "src/components/ListPage"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { TemplateFeatureModal } from "src/modals/template-features"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import {
    FilterModel,
    IAdvancedOperations,
    SortModel,
} from "src/types/data-grid-pro"
import { ConfirmModal } from "src/modals/confirm"
import { Repository } from "src/types/channel"
import { useInitializer } from "src/lib/initializer"
import { useGetTemplateFeatureColumns } from "src/views/template-features/hooks/useGetTemplateFeatureColumns"
import { ITemplateFeature } from "src/views/template-features/types/template-feature"
import { IPageFilterProps } from "src/components/PageFilter"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"
import { removeLastIdSegment } from "src/helpers/removeLastIdSegment"
import { reportError } from "src/lib/report"
import { EModalMode } from "src/types/modal/modal"

const repository: Repository = "template-feature"

const View = observer(() => {
    const store = useStore(TemplateFeatureStore)
    const globalStore = useStore(GlobalStore)

    const { id } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const parentPath = removeLastIdSegment(location.pathname)

    const columns = useGetTemplateFeatureColumns()

    const advancedOperationsModel: IAdvancedOperations = useMemo(
        () => ({
            pagination: "server",
            filtering: "server",
            sorting: "server",
        }),
        [],
    )

    const advanceQuery = useMemo(() => {
        const filter = getFilterModelForRepository(
            repository,
            globalStore.session.dataGridFilterModel,
        )
        const sort = getSortModelForRepository(
            repository,
            globalStore.session.dataGridSortModel,
        )
        return { sort, filter }
    }, [
        globalStore.session.dataGridFilterModel,
        globalStore.session.dataGridSortModel,
    ])

    const initialized = useInitializer(async () => {
        await store.init(
            globalStore.session.accessGroup.id ?? DEFAULT_ACCESS_GROUP.id,
            advanceQuery,
        )
    }, [globalStore.session.accessGroup])

    // Open the modal for a particular template feature from url
    useInitializer(async () => {
        if (id !== undefined && !isNaN(Number(id))) {
            await globalStore.modals.open(
                () => <TemplateFeatureModal id={Number(id)} />,
                {
                    onClose: () => navigate(parentPath),
                },
            )
        }
    }, [id])

    useEffect(() => {
        return () => {
            store.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleNewFeatureClick = useCallback(() => {
        globalStore.modals.open(() => <TemplateFeatureModal />)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCopyTemplateFeatureClick = useCallback(
        (item) => {
            if (item != null) {
                globalStore.modals.open(() => (
                    <TemplateFeatureModal id={item.id} mode={EModalMode.Copy} />
                ))
            } else {
                reportError(t`global.copy-feature-error`)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const header = useMemo(
        () => ({
            header: t`template-features.header`,
            breadcrumbs: [t`navigation.templates`, t`template-features.header`],
            createOptions: {
                item: t`template-features.create-new`,
                onClick: handleNewFeatureClick,
            },
        }),
        [handleNewFeatureClick],
    )

    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments, advanceQuery),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [advanceQuery],
    )

    const filter = useMemo(
        (): IPageFilterProps => ({
            actions: (
                <SegmentPickerButton
                    value={store.segments}
                    onChange={handleSegmentChange}
                />
            ),
        }),
        [store.segments, handleSegmentChange],
    )

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                globalStore.session.dataGridSortModel,
            )
            await store.query({ sort, filter: model })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalStore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                globalStore.session.dataGridFilterModel,
            )
            await store.query({ filter, sort: model })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalStore.session.dataGridFilterModel],
    )

    const handleEditItem = useCallback(
        (item: ITemplateFeature) => {
            globalStore.modals.open(() => <TemplateFeatureModal id={item.id} />)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const handleDeleteItem = useCallback(
        (item: ITemplateFeature) => {
            globalStore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={async (confirmed) => {
                            if (confirmed) {
                                await store.deleteItem(item.id)
                            }
                        }}
                        title={t`template-features.are-you-sure`}
                        content={`${t`template-features.confirm-delete-item`} ${
                            item.name
                        }`}
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const rowActionsRenderer = useCallback(
        (item: ITemplateFeature) => [
            {
                text: t`template-features.edit-item`,
                onClick: () => handleEditItem(item),
            },
            {
                text: t`template-features.copy-template-feature`,
                onClick: () => handleCopyTemplateFeatureClick(item),
            },
            {
                text: t`template-features.delete-item`,
                onClick: () => handleDeleteItem(item),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    return (
        <ListPage header={header} filter={filter} loading={!initialized}>
            <DataGridProTable
                paginator={store.templateFeatures}
                data={store.templateFeatures.items}
                columns={columns}
                advancedOperations={advancedOperationsModel}
                onRowClickEvent={(params) => handleEditItem(params.row)}
                rowActionsRenderer={rowActionsRenderer}
                repository={repository}
                loading={!initialized}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const TemplateFeatureView = () => (
    <StoreProvider Store={TemplateFeatureStore}>
        <View />
    </StoreProvider>
)
