import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { TemplateFeatureModalStore } from "src/modals/template-features/store"
import { ModalTextField } from "src/components/ModalTextField"
import { useStore } from "src/store/lib/useStore"
import { AccessGroup } from "src/components/ModalAccessGroup/AccessGroup"

export const TemplateFeatureSettingsSection = observer(() => {
    const store = useStore(TemplateFeatureModalStore)

    const handleNameChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            store.formFields.set("name", e.target.value)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const handleDescriptionChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            store.formFields.set("description", e.target.value)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const handleAccessGroupChange = useCallback(
        (accessGroupId: number) => {
            store.formFields.set("access_group_id", accessGroupId)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    return (
        <>
            <ModalTextField
                variant="default"
                label={t`template-features.settings-section.name`}
                value={store.formFields.get("name")}
                onChange={handleNameChange}
                error={Boolean(store.formFields.error("name"))}
                helperText={store.formFields.error("name")}
                disabled={store.isReadOnly}
            />
            <ModalTextField
                variant="default"
                label={t`template-features.settings-section.description`}
                value={store.formFields.get("description")}
                onChange={handleDescriptionChange}
                error={Boolean(store.formFields.error("description"))}
                helperText={store.formFields.error("description")}
                rows={4}
                disabled={store.isReadOnly}
            />
            <AccessGroup
                disabled={store.isEditMode || store.isReadOnly}
                accessGroupId={store.formFields.get("access_group_id")}
                errorMessage={store.formFields.error("access_group_id") ?? ""}
                onChange={handleAccessGroupChange}
            />
        </>
    )
})
