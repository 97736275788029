import { styled } from "@mui/material"

export const FieldTypeDropdownIconContainer = styled("div")(({ theme }) => ({
    width: "1.5rem",
    borderRadius: theme.shape.borderRadius,
    marginRight: "0.5rem",
    paddingTop: "0.5rem",
}))

export const FieldTypeDropdownItemContainer = styled("span")({
    display: "flex",
    alignItems: "center",
})
